import React, { useState, useEffect } from 'react';
import { Box, TextField, MenuItem, Grid, Alert, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import { api } from 'src/Api';

const EditBulkLearnersForm = ({ selectedIds, refreshAdmin }) => {
  const [formData, setFormData] = useState({
    qualification: '',
    assessor: '',
    iqa: '',
    message: ''
  });

  const [qualifications, setQualifications] = useState([]);
  const [assessors, setAssessors] = useState([]);
  const [iqas, setIqas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ visible: false, message: '' });
  const [selectedFile, setSelectedFile] = useState(null);

  const handleChange = (e) => {
    setError({ visible: false, message: '' });
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileDelete = () => {
    setSelectedFile(null);
  };

  const getQualifications = () => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    fetch(
      `${api.get_qualifications}?user_id=${user?.id}&company_admin_id=${user?.company_admin_id}`,
      {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${token}`
        })
      }
    )
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setQualifications(result.data);
        } else {
          setQualifications([]);
        }
      })
      .catch((error) => console.log('error', error));
  };

  const getAssessors = () => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    fetch(`${api.get_assessors}?company_admin_id=${user?.company_admin_id}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`
      })
    })
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setAssessors(result.data);
        } else {
          setAssessors([]);
        }
      })
      .catch((error) => console.log('error', error));
  };

  const getIqas = () => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    fetch(`${api.get_iqas}?company_admin_id=${user?.company_admin_id}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`
      })
    })
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setIqas(result.data);
        } else {
          setIqas([]);
        }
      })
      .catch((error) => console.log('error', error));
  };

  const handleSubmit = () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formData?.qualification && formdata.append('qualification_id', formData.qualification.id);
    formData.assessor && formdata.append('assessor_id', formData.assessor.id);
    formData.iqa && formdata.append('iqa_id', formData.iqa.id);
    formData?.message.length > 0 &&
      formData?.message != undefined &&
      formdata.append('message', formData?.message);
    formdata.append('ids', selectedIds.toString());

    if (selectedFile) {
      formdata.append('attachment', selectedFile);
    }

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.bulk_edit, requestOptions)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          return response.text();
        } else {
          return response.json().then((result) => {
            setError({ visible: true, message: result.error });
          });
        }
      })
      .then((result) => {
        const response = JSON.parse(result);
        refreshAdmin();
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getQualifications();
    getAssessors();
    getIqas();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        p: 0,
        paddingTop: 2,
        paddingBottom: 0
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {/* {qualifications.length > 0 && ( */}
          <TextField
            select
            fullWidth
            label="Select Qualification/Subject"
            variant="outlined"
            required
            name="qualification"
            value={formData.qualification}
            onChange={handleChange}
          >
            {qualifications.map((item) => (
              <MenuItem key={item.id} value={item}>
                {item?.sub_title}
              </MenuItem>
            ))}
          </TextField>
          {/* )} */}
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* {assessors.length > 0 && ( */}
          <TextField
            select
            fullWidth
            label="Select Assessor"
            variant="outlined"
            name="assessor"
            required
            value={formData.assessor}
            onChange={handleChange}
          >
            {assessors.map((item) => (
              <MenuItem key={item.id} value={item}>
                {item?.full_name}
              </MenuItem>
            ))}
          </TextField>
          {/* )} */}
        </Grid>
        <Grid item xs={12} sm={12}>
          {/* {iqas.length > 0 && ( */}
          <TextField
            select
            fullWidth
            label="Select IQA"
            variant="outlined"
            name="iqa"
            required
            value={formData.iqa}
            onChange={handleChange}
          >
            {iqas.map((item) => (
              <MenuItem key={item.id} value={item}>
                {item?.full_name}
              </MenuItem>
            ))}
          </TextField>
          {/* )} */}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Message"
            variant="outlined"
            name="Message"
            value={formData.message}
            onChange={handleChange}
          />
        </Grid>

        {error.visible && (
          <Grid item xs={12}>
            <Alert style={{ marginBottom: 20 }} severity="error">
              {error.message}
            </Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <input
            accept="*"
            id="upload-file"
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <label htmlFor="upload-file">
            <LoadingButton variant="outlined" component="span">
              Upload File
            </LoadingButton>
          </label>
          {selectedFile && (
            <Box display="flex" alignItems="center" mt={2}>
              <span>{selectedFile.name}</span>
              <IconButton onClick={handleFileDelete} color="error">
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            loading={loading}
          >
            Update
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditBulkLearnersForm;
