import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Grid, Chip, Alert, FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { api } from 'src/Api';
import { useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const AssessorsForm = ({ edit, selectedAssessor, handleClose, refreshPage }) => {
    const theme = useTheme();
    const [formDataAssessors, setFormDataAssessors] = useState({
        id: null,
        firstName: '',
        middleName: '',
        surname: '',
        qualification: [],
        email: '',
        contact: '',
    });

    console.log("🚀 ~ AssessorsForm ~ formDataAssessors:", formDataAssessors)


    const [error, setError] = useState({ visible: false, message: '' });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setError({ visible: false, message: '' });
        const { name, value } = e.target;
        setFormDataAssessors((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setFormDataAssessors((prevData) => ({
            ...prevData,
            qualification: typeof value === 'string' ? value.split(',') : value
        }));
    };

    const [qualifications, setQualifications] = useState([]);

    const getQualifications = () => {
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        fetch(`${api.get_qualificationsV1}?user_id=${user?.id}&company_admin_id=${user?.company_admin_id}&isEdit=${true}`, {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token}`
            })
        })
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setQualifications(result.data);
                } else {
                    setQualifications([]);
                }
            })
            .catch((error) => console.log('error', error));
    };

    useEffect(() => {
        getQualifications();
    }, []);

    useEffect(() => {
        if (edit) {
            setFormDataAssessors({
                referenceNumber: selectedAssessor?.user_qualifications[0]?.ref_number || '',
                firstName: selectedAssessor?.user_qualifications[0]?.first_name || '',
                middleName: selectedAssessor?.user_qualifications[0]?.middle_name || '',
                surname: selectedAssessor?.user_qualifications[0]?.sur_name || '',
                qualification: selectedAssessor?.user_qualifications?.map((e) => e?.qualifications_with_trash[0]?.sub_title) || [],
                email: selectedAssessor?.email || '',
                contact: selectedAssessor?.user_qualifications[0]?.contact || '',
            });
        }
    }, []);

    const appendIfNotEmpty = (formDataAssessors, key, value) => {
        if (value !== null && value !== undefined && value !== '') {
            formDataAssessors.append(key, value);
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);

        const checkQualifications = qualifications
            .filter((a) => formDataAssessors.qualification.includes(a.sub_title))
        const formdata = new FormData();

        if (edit) appendIfNotEmpty(formdata, 'id', selectedAssessor?.id);
        appendIfNotEmpty(formdata, 'first_name', formDataAssessors.firstName);
        appendIfNotEmpty(formdata, 'role_id', '4');
        appendIfNotEmpty(formdata, 'middle_name', formDataAssessors.middleName);
        appendIfNotEmpty(formdata, 'sur_name', formDataAssessors.surname);
        appendIfNotEmpty(formdata, 'qualifications', JSON.stringify(checkQualifications));
        appendIfNotEmpty(formdata, 'email', formDataAssessors.email);
        appendIfNotEmpty(formdata, 'contact', formDataAssessors.contact);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(api.create_user, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 201 || status === 200) {
                    refreshPage();
                } else {
                    setError({ visible: true, message: result.error });
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <Box
            sx={{
                width: '100%',
                bgcolor: 'background.paper',
                p: 0,
                paddingTop: 2,
                paddingBottom: 0
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        disabled={edit}
                        fullWidth
                        variant="outlined"
                        label="Code"
                        name="Code"
                        value={selectedAssessor?.code}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={!edit}
                        fullWidth
                        label="First Name"
                        variant="outlined"
                        name="firstName"
                        value={formDataAssessors.firstName}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={!edit}
                        fullWidth
                        label=" Middle Name"
                        variant="outlined"
                        name="middleName"
                        value={formDataAssessors.middleName}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={!edit}
                        fullWidth
                        label="Surname"
                        variant="outlined"
                        required
                        name="surname"
                        value={formDataAssessors.surname}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="qualification">Select Qualification/s</InputLabel>
                        <Select
                            labelId="qualification-label"
                            id="qualification"
                            multiple
                            value={formDataAssessors.qualification}
                            onChange={handleSelectChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Select Qualification/s" />}
                            MenuProps={MenuProps}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip label={value} />
                                    ))}
                                </Box>
                            )}
                        >
                            {qualifications.map((name) => (
                                <MenuItem
                                    key={name.id}
                                    value={name.sub_title}
                                    style={getStyles(name, formDataAssessors.qualification, theme)}
                                >
                                    <Typography>
                                        {name.sub_title}
                                    </Typography>
                                    {name.deleted_at && <Typography sx={{ fontSize: 10, color: "red", marginLeft: 1, fontWeight: "bold", marginTop: 0.7 }}>
                                        DELETED
                                    </Typography>}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={!edit}
                        fullWidth
                        type="email"
                        label="Email ID"
                        variant="outlined"
                        name="email"
                        value={formDataAssessors.email}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={!edit}
                        fullWidth
                        label="Contact #"
                        variant="outlined"
                        name="contact"
                        type='number'
                        value={formDataAssessors.contact}
                        onChange={handleChange}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                    <TextField
                        type='text'
                        disabled={!edit}
                        fullWidth
                        label="Sampling Ratio"
                        variant="outlined"
                        name="samplingRatio"
                        value={`${formDataAssessors.samplingRatio}%`}
                        onChange={(e) => {
                            let value = e.target.value;

                            // Remove any '%' and extra spaces, then parse the number
                            value = value.replace('%', '').trim();

                            // Only proceed if the value is a valid number between 0 and 100
                            if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 100)) {
                                handleChange({
                                    target: {
                                        name: e.target.name,
                                        value: value ? `${value}` : '',
                                    },
                                });
                            }
                        }}
                        inputProps={{ min: 0, max: 100, step: 0.01 }}
                    />
                </Grid> */}

                {error?.visible && (
                    <Grid item xs={12}>
                        <Alert style={{ marginBottom: 20 }} severity="error">
                            {error?.message}
                        </Alert>
                    </Grid>
                )}
                {edit && (
                    <Grid item xs={12}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                                handleSubmit(formDataAssessors);
                            }}
                            loading={loading}
                        >
                            Submit
                        </LoadingButton>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default AssessorsForm;
