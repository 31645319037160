import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Link as RouterLink } from 'react-router-dom';

import {
    Card,
    Table,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Modal,
    CircularProgress,
    Pagination,
    CardContent,
    TextField,
    Grid,
    Alert,
    Snackbar,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import { UserListHead } from 'src/sections/@dashboard/user';
import { api } from 'src/Api';
import { ArrowBack, ExpandMore, Add, Close, CloudUpload } from '@mui/icons-material';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import Iconify from 'src/components/Iconify';

import Iframe from '../Iframe';

export default function ResourceMaterialTab({ id, qualification_id }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [permission, setpermission] = useState([]);
    const requirement = ['visible', 'add', 'edit', 'delete'];

    const [search, setsearch] = useState('');
    const [resourceData, setResourceData] = useState([]);
    const [loading, setloading] = useState(true);
    const [searchLoading, setsearchLoading] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [page, setPage] = useState(1);
    const [selectedId, setSelectedId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isDeleteLoading, setDeleteLoading] = useState(false);
    const [deletingItemId, setDeletingItemId] = useState(null);

    const [iframeURL, setIframeURL] = useState('');
    const [iframeModal, setIframeModal] = useState(false);

    const isUserNotFound = resourceData.length === 0;

    useEffect(() => {
        if (selectedId) {
            handleDelete()
        }
    }, [selectedId])

    useEffect(() => {
        checkRender();
        getResourceData()
    }, []);

    useEffect(() => {
        if (search.length == 0) {
            resourceData.length = 0;
            getResourceData(page);
        }
    }, [search]);

    function checkRender() {
        let check = 'resource materials';
        const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
            (item) => item.permission_name.toLowerCase() == check
        );
        localPerm[0]?.permission_types?.filter((items) =>
            requirement.includes(items.permission_type_name.toLowerCase())
                ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
                : false
        );
    }

    const handlePageChange = (event, value) => {
        resourceData.length = 0;
        setPage(value);
        getResourceData(value);
    };

    const getResourceData = () => {
        setloading(true)
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        console.log("🚀 ~ getResourceData ~ user:", user)
        myHeaders.append('Authorization', `Bearer ${token}`);

        const queryParams = new URLSearchParams();
        search.length > 0 && queryParams.append('search', search);
        page && queryParams.append('page', page);
        queryParams.append('company_admin_id', user?.company_admin_id);
        queryParams.append('qualification_id', qualification_id);
        queryParams.append('user_id', user.id);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${api.get_resource_materail}?${queryParams.toString()}`, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setloading(false)
                    setResourceData(result.data)
                    result?.count ? setpagesCount(Math.ceil(result?.count / 10)) : setpagesCount(1);
                    setsearchLoading(false);
                } else {
                    setloading(false)
                    setResourceData([]);
                    setpagesCount(0);
                    setsearchLoading(false);
                }
            })
            .catch((error) => {
                console.log('error', error)
                setloading(false);
                setsearchLoading(false);
            });
    };

    const handleDelete = () => {
        setDeleteLoading(true);
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${api.delete_resource_materail}?id=${selectedId}`, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                    setDeleteLoading(false);
                    getResourceData()
                } else {
                    setDeleteLoading(false);
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                }
            })
            .catch((error) => {
                setDeleteLoading(false);
                setSnackbarMessage('An error occurred.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const triggerDownload = (url, fileName) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.target = 'blank'
        link.style.display = 'none'; // Hide the link
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleCloseIframe = () => {
        setIframeURL('')
        setIframeModal(false)
      }

    return (
        <Box>
            <Card>
                {loading && !searchLoading ? (
                    <Box
                        py={5}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flex: 1
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )
                    :
                    < Scrollbar sx={{ padding: "0px 20px 20px 20px" }}>
                        {resourceData.map((val) => {
                            return (
                                <Accordion sx={{ mt: 1 }}>

                                    <AccordionSummary
                                        sx={{
                                            minHeight: '40px !important',
                                            maxHeight: '40px !important',
                                            backgroundColor: "#eeeeee",
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '0px 16px',  // Adjust padding as needed
                                        }}
                                        expandIcon={<ExpandMore />}
                                    >
                                        <Iconify
                                            color={"#000000"}
                                            icon="material-symbols:folder"
                                            sx={{ marginTop: 0.5, fontSize: 20 }}
                                        />
                                        <Typography sx={{ marginTop: 0.5, flex: 1, marginLeft: 1 }}>{val.folder_name}</Typography>

                                    </AccordionSummary>

                                    {val.files.length > 0 &&
                                        <AccordionDetails>
                                            <Box sx={{ mt: 0, mb: 0 }}>
                                                {val.files.map((ac, acIndex) => (
                                                    <Grid container sx={{ padding: '7px 10px 7px 10px !important', backgroundColor: "#eeeeee", }}>
                                                        <Iconify
                                                            color={"#000000"}
                                                            icon="mdi:file"
                                                            sx={{ marginTop: 0.5, fontSize: 20 }}
                                                        />
                                                        <Box sx={{ flex: 1 }}>
                                                            <RouterLink
                                                                // target='blank'
                                                                className="routerLink"
                                                                // to={ac.file}
                                                                onClick={() => {
                                                                    setIframeModal(true)
                                                                    setIframeURL(ac.file)
                                                                }}
                                                            >
                                                                <Typography sx={{ marginTop: 0.5, flex: 1, marginLeft: 1 }}>{ac.file_name}</Typography>
                                                            </RouterLink>
                                                        </Box>
                                                        <Grid flexDirection={"row"} alignItems={"center"}>
                                                            <Button
                                                                size="small"
                                                                style={{
                                                                    justifyContent: 'flex-start',
                                                                    width: 100,
                                                                    alignSelf: 'center',
                                                                    marginRight: 3,
                                                                    fontSize: 11
                                                                }}
                                                                variant="outlined"
                                                                onClick={() => triggerDownload(ac.file, ac.file_name)}
                                                                startIcon={
                                                                    <Iconify
                                                                        color={"#000000"}
                                                                        icon="ic:baseline-download"
                                                                    />
                                                                }
                                                            >
                                                                {isDeleteLoading && deletingItemId === ac.id ? (
                                                                    <CircularProgress size={16} sx={{ color: '#000000' }} />
                                                                ) : (
                                                                    'Download'
                                                                )}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Box>
                                        </AccordionDetails>}
                                </Accordion>
                            )
                        })
                        }
                    </Scrollbar>
                }

                {isUserNotFound && !loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                        <SearchNotFound message={`No Resource Material Found`} />
                    </Box>
                )}

                <Iframe url={iframeURL} setDialogOpen={handleCloseIframe} dialogOpen={iframeModal} />
            </Card>
        </Box >
    );
}