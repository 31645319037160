import React, { useState, useEffect } from 'react';
import { Box, TextField, MenuItem, Grid, Alert, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { api } from 'src/Api';

const LearnersForm = ({
  formData,
  setFormData,
  handleSubmit,
  loading,
  error,
  setAlert,
  editable
}) => {
  const handleChange = (e) => {
    setAlert({ visible: false, message: '' });
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleCheckboxChange = (event) => {
    setFormData({ ...formData, disability: event.target.checked });
  };

  const [qualifications, setqualifications] = useState([]);

  function getQualifications() {
    const myHeaders = new Headers();

    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    // Append the user_id as a query parameter to the API endpoint URL
    const url = `${api.get_qualificationsV1}?user_id=${user?.id}&company_admin_id=${user?.company_admin_id}&isEdit=${false}`;


    fetch(url, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setqualifications(result.data);
          if (formData.qualification.length > 0) {
            setFormData((prevData) => ({
              ...prevData,
              ['qualification']: result.data.filter(
                (item) => item.id === formData.qualification[0].id
              )[0]
            }));
          }
        } else {
          setqualifications([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => { });
  }

  const [assessors, setassessors] = useState([]);

  function getAssessors(qualification_id) {
    const myHeaders = new Headers();

    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    // Append the user_id as a query parameter to the API endpoint URL
    const url = `${api.get_assessorsV1}?user_id=${user?.id}&company_admin_id=${user?.company_admin_id}&qualification_id=${qualification_id}&isEdit=${false}`;

    fetch(url, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setassessors(result.data);
          if (formData.assessor.length > 0) {
            setFormData((prevData) => ({
              ...prevData,
              ['assessor']: result.data.filter((item) => item.id === formData.assessor[0].id)[0]
            }));
          }
        } else {
          setassessors([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => { });
  }

  const [iqas, setiqas] = useState([]);

  function getIqas(qualification_id) {
    const myHeaders = new Headers();

    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    // Append the user_id as a query parameter to the API endpoint URL
    const url = `${api.get_iqasV1}?user_id=${user?.id}&company_admin_id=${user?.company_admin_id}&qualification_id=${qualification_id}&isEdit=${false}`;

    fetch(url, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setiqas(result.data);
          if (formData.iqa.length > 0) {
            setFormData((prevData) => ({
              ...prevData,
              ['iqa']: result.data.filter((item) => item.id === formData.iqa[0].id)[0]
            }));
          }
        } else {
          setiqas([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => { });
  }

  useEffect(() => {
    getQualifications();
  }, []);

  useEffect(() => {
    if (formData.qualification.id) {
      getAssessors(formData.qualification.id);
      getIqas(formData.qualification.id);
    }
  }, [formData.qualification.id]);

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        p: 0,
        paddingTop: 2,
        paddingBottom: 0
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            label="Reference Number"
            variant="outlined"
            name="referenceNumber"
            value={formData.referenceNumber}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            label="First Name"
            variant="outlined"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            label=" Middle Name"
            variant="outlined"
            name="middleName"
            value={formData.middleName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            label="Surname"
            variant="outlined"
            required
            name="surname"
            value={formData.surname}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            label="Learner Number (if any)"
            variant="outlined"
            name="learnerNumber"
            value={formData.learnerNumber}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* {qualifications.length > 0 && ( */}
          <TextField
            disabled={!editable}
            select
            fullWidth
            label="Select Qualification/Subject"
            variant="outlined"
            required
            name="qualification"
            value={formData.qualification}
            onChange={handleChange}
          >
            {qualifications.map((item) => {
              return <MenuItem value={item}>{item?.sub_title}</MenuItem>;
            })}
          </TextField>
          {/* )} */}
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* {assessors.length > 0 && ( */}
          <TextField
            disabled={!editable}
            select
            required
            fullWidth
            label="Select Assessor"
            variant="outlined"
            name="assessor"
            value={formData.assessor}
            onChange={handleChange}
          >
            {assessors.map((item) => {
              return <MenuItem value={item}>{item?.full_name}</MenuItem>;
            })}
          </TextField>
          {/* )} */}
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* {iqas.length > 0 && ( */}
          <TextField
            disabled={!editable}
            select
            fullWidth
            required
            label="Select IQA"
            variant="outlined"
            name="iqa"
            value={formData.iqa}
            onChange={handleChange}
          >
            {iqas.map((item) => {
              return <MenuItem value={item}>{item?.full_name}</MenuItem>;
            })}
          </TextField>
          {/* )} */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            type="date"
            label="Date of Registration"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            name="registrationDate"
            value={formData.registrationDate}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            label="Cohort/Batch #"
            variant="outlined"
            name="cohortBatch"
            value={formData.cohortBatch}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            type="email"
            label="Email"
            variant="outlined"
            name="email"
            required
            value={formData.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            label="Contact #"
            variant="outlined"
            name="contact"
            value={formData.contact}
            onChange={handleChange}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            inputProps={{ max: new Date().toISOString().split('T')[0] }}
            type="date"
            label="Date of Birth"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            name="dob"
            value={formData.dob}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.disability}
                onChange={handleCheckboxChange}
                name="Disability (if any)"
                color="primary"
              />
            }
            label="Disability (if any)"
            sx={{ mt: 1 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={!editable}
            fullWidth
            multiline
            rows={4}
            label="Address"
            variant="outlined"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </Grid>

        {error?.visible && (
          <Grid item xs={12}>
            <Alert style={{ marginBottom: 20 }} severity="error">
              {error?.message}
            </Alert>
          </Grid>
        )}

        {editable && (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                handleSubmit(formData);
              }}
              loading={loading}
            >
              Submit
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default LearnersForm;
