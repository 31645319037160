import React, { useState, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Container,
  Stack,
  IconButton,
  Modal,
  TextField,
  Snackbar,
  Alert
} from '@mui/material';
import moment from 'moment';
import { api } from 'src/Api';
import SearchNotFound from 'src/components/SearchNotFound';
import { useLocation, useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';
import { Link as RouterLink } from 'react-router-dom';

import { ArrowBack } from '@mui/icons-material';
import Iframe from 'src/components/Users/Iframe';

const ViewEvidence = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { values } = location.state;

  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openChange, setOpenChange] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openFeedback2, setOpenFeedback2] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [selectedAttachment, setSelectedAttachment] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [iframeURL, setIframeURL] = useState('');
  const [iframeModal, setIframeModal] = useState(false);

  const handleChangeModal = (submission) => {
    setSelectedAttachment(submission.attachement)
    setOpenChange(true);
  };

  const handleChangeModalClose = (id) => {
    setOpenChange(false);
  };

  const handleFeedbackModal = (submission) => {
    setOpenFeedback(true);
  };

  const handleFeedbackModal2 = (submission) => {
    setOpenFeedback2(true);
  };

  const handleFeedbackModalClose2 = (id) => {
    setOpenFeedback2(false);
    setSelectedFeedback(null)
    setFeedbackText('')
  };
  const handleFeedbackModalClose = (id) => {
    setOpenFeedback(false);
    setSelectedFeedback(null)
    setFeedbackText('')
  };

  useEffect(() => {
    getEvidence();
  }, []);

  const userData = JSON.parse(localStorage.getItem('user'));

  const getEvidence = () => {
    setLoading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    myHeaders.append('Authorization', `Bearer ${token}`);

    const queryParams = new URLSearchParams();
    values && queryParams.append('user_id', values?.user_id);
    values && queryParams.append('qualification_id', values?.qualification_id);
    values && queryParams.append('ac_id', values?.ac_id);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.get_evidence}?${queryParams.toString()}`, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setList(result?.data);
        } else {
          setList(null);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFeedkback = () => {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formData = new FormData();
    formData.append('qualification_id', values.qualification_id);
    formData.append('submission_id', selectedFeedback?.id);
    formData.append('ac_id', values.ac_id);
    feedbackText && formData.append('feedback', feedbackText);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow',
    };
    fetch(api.save_assessor_feedback, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 201 || status === 200) {
          setSnackbarMessage(result.message);
          setSnackbarOpen(true);
          setSnackbarSeverity('success');
          setTimeout(() => {
            handleFeedbackModalClose()
            getEvidence()
          }, 2000);
        } else {
          console.error('Error uploading file:', result);
          setSnackbarMessage(result.message);
          setSnackbarOpen(true);
          setSnackbarSeverity('error');
          setTimeout(() => {
            handleFeedbackModalClose()
          }, 2000);
        }
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
      })
      .finally(() => { });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <CircularProgress color="inherit" size={50} />
      </Box>
    );
  }

  if (!list) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 5, backgroundColor: 'white' }}>
        <SearchNotFound message="No submissions found." />
      </Box>
    );
  }

  const boxStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: '40%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 2,
    border: '2px solid',
    borderColor: 'primary.main',
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
    maxHeight: 600,
    overflow: "scroll",
    overflowX: "hidden"
  };

  const assessment_criterion = list?.assessment_criterion;
  const submissions = list?.submission_list;

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleModalOpenFeekBack = (submission) => {
    setSelectedFeedback(submission)
    handleFeedbackModal();
  };

  const handleModalOpenFeekBack2 = (submission) => {
    setSelectedFeedback(submission)
    handleFeedbackModal2();
  };

  const handleCloseIframe = () => {
    setIframeURL('')
    setIframeModal(false)
  }

  return (
    <Page title={`${values?.name} | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        <Stack direction="row" alignItems="center" mb={1}>
          <IconButton style={{ marginRight: 12, marginBottom: 5 }} onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h4" gutterBottom>
            {values?.name}
          </Typography>
        </Stack>

        <Card sx={{ mb: 5, p: 0 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" gutterBottom>
                  Assessment Criterion
                </Typography>
                <Typography style={{ textTransform: 'capitalize' }}>
                  {assessment_criterion.ac_detail || 'N/A'} - {assessment_criterion?.ac_number || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" gutterBottom>
                  Assessor Name
                </Typography>
                <Typography style={{ textTransform: 'capitalize' }}>
                  {list?.assessor?.full_name || 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Submission Date</TableCell>
                <TableCell>Outcome</TableCell>
                <TableCell>Evidence</TableCell>
                <TableCell>Feedback</TableCell>
                <TableCell>Assessment Date</TableCell>
              </TableRow>
            </TableHead>

            {submissions.length > 0 ? (
              <TableBody>
                {submissions?.map((submission, index) => (
                  <TableRow key={index}>

                    {/* Submission Date */}
                    <TableCell>
                      {submission?.submission_date
                        ? moment(submission?.submission_date).format('DD/MM/YYYY')
                        : 'N/A'}
                    </TableCell>

                    {/* Outcome */}
                    <TableCell>{submission?.outcome || 'N/A'}</TableCell>

                    {/* Evidence */}
                    <TableCell>
                      <Button
                        sx={{ fontSize: 12 }}
                        variant='contained'
                        onClick={() => {
                          handleChangeModal(submission)
                        }}
                      >
                        View
                      </Button>
                    </TableCell>

                    {/* Feedback */}
                    <TableCell sx={{ flexDirection: "column", display: "flex" }}>
                      {submission?.feedback ?
                        <RouterLink
                          target='blank'
                          className="routerLink"
                          onClick={() => handleModalOpenFeekBack2(submission)}
                        >
                          <Typography noWrap>{submission?.feedback}</Typography>
                        </RouterLink>
                        :
                        <Button
                          sx={{ fontSize: 12, width: 80 }}
                          variant='outlined'
                          onClick={() => {
                            handleModalOpenFeekBack(submission)
                          }}
                        >
                          Add
                        </Button>
                      }
                    </TableCell>

                    {/* Assessment Date */}
                    <TableCell>{submission?.assessment_date ? moment(submission?.assessment_date).format('DD/MM/YYYY') : 'N/A'}</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={9}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                      <SearchNotFound message={`No ${values?.name} Found.`} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Container>

      {/* EVIDENCE MODAL */}
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }}
        open={openChange}
        onClose={handleChangeModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...boxStyle, maxWidth: "80%" }} className="admin_modal_pa">

          <Box p={"20px 20px 0px 20px"} flexDirection={'column'} display={'flex'} flex={1}>
            <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              View Evidence
            </Typography>
            <CardContent>
              {selectedAttachment.map((val, index) => {
                return (
                  <Box sx={{ display: "flex" }}>
                    <Typography mr={1}>{index + 1} :</Typography>
                    <RouterLink
                      // target='blank'
                      className="routerLink"
                      // to={val.attachement}

                      onClick={() => {
                        setIframeModal(true)
                        setIframeURL(val.attachement)
                      }}
                    >
                      <Typography noWrap>{val.attachement || 'N/A'}</Typography>
                    </RouterLink>
                  </Box>
                )
              })}
            </CardContent>
            <Button style={{ marginTop: 10, display: "flex", alignSelf: "self-end" }} color="primary" variant="outlined" onClick={handleChangeModalClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* FEEDBACK MODAL */}
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
        open={openFeedback}
        onClose={handleFeedbackModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...boxStyle, style }} className="admin_modal_pa">
          <Box p={"20px 20px 0px 20px"} flexDirection={'column'} display={'flex'} flex={1}>
            <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Feedback
            </Typography>

            <TextField
              sx={{ marginTop: 2, marginBottom: 2 }}
              fullWidth
              variant="outlined"
              label="Feedback"
              name="feedback"
              multiline
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <Button variant="contained" style={{ marginTop: 10, marginLeft: 10 }} color="primary" onClick={handleFeedbackModalClose}>
                Close
              </Button>
              <Button variant="contained" style={{ marginTop: 10, marginLeft: 10 }} color="primary" onClick={handleFeedkback}>
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
        open={openFeedback2}
        onClose={handleFeedbackModalClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...boxStyle, style }} className="admin_modal_pa">
          <Box p={"20px 20px 0px 20px"} flexDirection={'column'} display={'flex'} flex={1}>
            <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Feedback
            </Typography>

            <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Feedback
            </Typography>
          </Box>
        </Box>
      </Modal>

      <Iframe url={iframeURL} setDialogOpen={handleCloseIframe} dialogOpen={iframeModal} />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </Page >
  );
};

export default ViewEvidence;
