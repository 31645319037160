import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  LockReset as LockResetIcon,
  Done as DoneIcon,
  Edit as EditIcon,
  Download as DownloadIcon,
  Block as BlockIcon,
  Delete as DeleteIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import LearnersForm from '../LearnerForm';
import { api } from 'src/Api';
import { useNavigate } from 'react-router-dom';

const OptionsTab = ({ id, onRefresh, qualification_id }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedLearner, setSelectedLearner] = useState(null);
  const [user, setuser] = useState(null);
  console.log("🚀 ~ OptionsTab ~ user:", user)
  const [loading, setloading] = useState(false);
  const [loadingReset, setloadingReset] = useState(false);
  const [deactiveLoader, setdeactiveLoader] = useState(false);
  const [deleteLoader, setdeleteLoader] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [openAlertModalD, setOpenAlertModalD] = useState(false);

  const handleOpenAlert = () => {
    setOpenAlertModal(true);
  };

  const handleCloseAlert = () => {
    setOpenAlertModal(false);
  };

  const handleOpenAlertD = () => {
    setOpenAlertModalD(true);
  };

  const handleCloseAlertD = () => {
    setOpenAlertModalD(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedLearner(null);
  };

  const refreshPage = () => {
    setOpen(false);
    setSelectedLearner(null);
    get_user_by_id();
    onRefresh();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  function get_user_by_id() {
    setloading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    myHeaders.append('Authorization', `Bearer ${token}`);

    const queryParams = new URLSearchParams();
    id && queryParams.append('user_id', id);
    queryParams.append('company_admin_id', user?.company_admin_id);
    queryParams.append('qualification_id', qualification_id);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.get_user_by_id}?${queryParams.toString()}`, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setuser(result?.data);
        } else {
          setuser(null);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
      });
  }

  useEffect(() => {
    get_user_by_id();
  }, []);

  const openNewWindow = () => {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    const url = `https://ezsapi.dotserviz.com/api/v1/users/download_portfolio?company_admin_id=${loggedInUser?.company_admin_id}&learner_id=${user?.id}`;
    window.open(url, '_blank');
  };

  function action_user_status(userId, status) {
    if (status === 'inactive' || status === 'active') setdeactiveLoader(true);
    if (status === 'deleted') setdeleteLoader(true);

    const myHeaders = new Headers();
    const token = localStorage.getItem('token');

    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('status', status);
    formdata.append('id', userId);
    formdata.append('company_admin_id', user?.company_admin_id);
    formdata.append('qualification_id', qualification_id);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.action_on_user, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
      })
      .then((result) => {
        handleCloseAlert()
        handleCloseAlertD()
        setSnackbarMessage(
          `Learner successfully ${status == 'inactive' ? 'de-activated' : status == 'deleted' ? 'deleted' : 're-activated'}`
        );
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate(-1)
        }, 1000);
      })
      .catch((error) => console.error('Error:', error))
      .finally(() => {
        if (status === 'inactive') setdeactiveLoader(false);
        if (status === 'deleted') setdeleteLoader(false);
      });
  }

  const handleResetPasswordLink = () => {
    setloadingReset(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.send_reset_password_link}?email=${user.email}&company_admin_id=${user?.company_admin_id}`, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setSnackbarMessage(result.message);
          setSnackbarOpen(true);
        } else {
          console.log('result', result)
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloadingReset(false);
      });
  }

  return (
    <Box>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
          <CircularProgress color="inherit" size={50} />
        </Box>
      ) : (
        <>
          {user && (
            <>
              <List>
                <ListItem>
                  <LoadingButton startIcon={<LockResetIcon />} fullWidth variant="outlined" onClick={() => handleResetPasswordLink()}
                    loading={loadingReset}
                  >
                    Reset Password
                  </LoadingButton>
                </ListItem>

                <ListItem>
                  <Button
                    startIcon={<EditIcon />}
                    fullWidth
                    variant="outlined"
                    onClick={handleOpen}
                  >
                    Edit Learner Details
                  </Button>
                </ListItem>

                <ListItem>
                  <Button
                    onClick={openNewWindow}
                    startIcon={<DownloadIcon />}
                    fullWidth
                    variant="outlined"
                  >
                    Download Portfolio
                  </Button>
                </ListItem>

                <ListItem>
                  <LoadingButton
                    disabled={user?.user_qualifications[0]?.status.toLowerCase() == 'deactive' ? true : false}
                    onClick={() => {
                      handleOpenAlert()
                    }}
                    startIcon={<BlockIcon />}
                    fullWidth
                    variant="outlined"
                  >
                    {user?.user_qualifications[0]?.status?.toLowerCase() == 'inactive' ? 'Re-activate Learner' : 'De-activate Learner'}
                  </LoadingButton>
                </ListItem>

                <ListItem>
                  <LoadingButton
                    onClick={() => {
                      handleOpenAlertD()
                    }}
                    startIcon={<DeleteIcon />}
                    fullWidth
                    variant="outlined"
                  >
                    Delete Learner
                  </LoadingButton>
                </ListItem>
              </List>

              <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
                PaperProps={{
                  style: {
                    overflow: 'hidden' // Prevent horizontal scroll
                  }
                }}
              >
                <Box position="relative">
                  <DialogTitle>Edit Learner Details</DialogTitle>
                  <IconButton
                    onClick={handleClose}
                    edge="end"
                    style={{
                      position: 'absolute',
                      top: 8,
                      right: 18
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <DialogContent>
                  <LearnersForm
                    edit={true}
                    selectedLearner={user}
                    handleClose={handleClose}
                    refreshPage={refreshPage}
                  />
                </DialogContent>
              </Dialog>

              <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                  {snackbarMessage}
                </Alert>
              </Snackbar>
            </>
          )}
        </>
      )}

      <Dialog
        open={openAlertModal}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert} color="primary">
            No
          </Button>
          <LoadingButton loading={deactiveLoader} onClick={() => {
            action_user_status(
              user?.id,
              user?.user_qualifications[0]?.status?.toLowerCase() == 'inactive' ? 'active' : 'inactive'
            );
          }}
            color="primary" autoFocus>
            Yes
          </LoadingButton >
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAlertModalD}
        onClose={handleCloseAlertD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlertD} color="primary">
            No
          </Button>
          <LoadingButton loading={deleteLoader} onClick={() => {
            action_user_status(user?.id, 'deleted');
          }}
            color="primary" autoFocus>
            Yes
          </LoadingButton >
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OptionsTab;
