import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    CircularProgress,
    IconButton,
    Modal,
    LinearProgress,
    Button,
    Snackbar,
    Alert,
    MenuItem,
    TextField,
    Paper,
    Grid,
    Divider,
} from '@mui/material';

import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import { UserListHead } from 'src/sections/@dashboard/user';
import { api } from 'src/Api';
import { Add, Close, CloudUpload } from '@mui/icons-material';
import Iconify from 'src/components/Iconify';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import pallete from '../../../theme/palette';
import Iframe from '../Iframe';

export default function AssessorDoc({ id, qualification_id }) {

    const ASSESSOR_TABLE_HEAD = [
        { id: 'title', label: 'Title', alignRight: false },
        { id: 'date', label: 'Date', alignRight: false },
        { id: 'attachment', label: 'Attachment', alignRight: false }
    ];

    const userData = JSON.parse(localStorage.getItem('user'));
    const [list, setlist] = useState([]);
    const [loading, setloading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [file, setFile] = useState([]);

    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [title, setTitle] = useState('');

    const [iframeURL, setIframeURL] = useState('');
    const [iframeModal, setIframeModal] = useState(false);

    function getAssessorDoc() {
        setloading(true);
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        myHeaders.append('Authorization', `Bearer ${token}`);

        const queryParams = new URLSearchParams();
        queryParams.append('company_admin_id', user?.company_admin_id);
        qualification_id && queryParams.append('user_id', id);
        qualification_id && queryParams.append('qualification_id', qualification_id);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders
        };

        fetch(`${api.get_assessor_documents}?${queryParams.toString()}`, requestOptions)
            .then((response) => {
                if (response.status === 200) {
                    return response.text();
                } else {
                    throw new Error('Failed to get brands');
                }
            })
            .then((result_) => {
                const result = JSON.parse(result_);
                list.length = 0;
                let decryptedData = result.data;
                setlist(decryptedData);
            })
            .catch((error) => {
                console.log('error', error);
                setlist([]);
            })
            .finally(() => {
                setloading(false);
            });
    }

    useEffect(() => {
        getAssessorDoc();
    }, []);

    const isDataNotFound = list.length === 0;

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleOpenAddModel = () => {
        setOpenModal(true)
    }

    const handleCloseAddModel = () => {
        setOpenModal(false)
        setFile([])
    }

    const style3 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: '600px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 1,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        outline: 0,
    };

    const handleFileChange = (e) => {
        const newFiles = Array.from(e.target.files);
        setFile((prevFiles) => {
            if (!Array.isArray(prevFiles)) {
                return { ...newFiles }; // Initialize state with new files if it's not an array
            }

            return [...prevFiles, ...newFiles]; // Append new files to existing state
        });
    };

    const handleRemoveFile = (fileName) => {
        setFile((prevFiles) => prevFiles.filter(file => file.name !== fileName));
    };

    const handleAddDocuments = () => {
        setSubmitLoading(true)
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);

        const formData = new FormData();
        formData.append('user_id', id);
        formData.append('qualification_id', qualification_id);
        formData.append('title', title);
        file.map((value, index) => {
            formData.append(`file[${index}]`, value);
        })

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow',
        };
        fetch(api.save_assessor_documents, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 201 || status === 200) {
                    setSnackbarMessage(result.message);
                    setSnackbarOpen(true);
                    setSnackbarSeverity('success');
                    setSubmitLoading(false)
                    setTimeout(() => {
                        handleCloseAddModel()
                        getAssessorDoc()
                    }, 2000);
                } else {
                    setSnackbarMessage(result.message);
                    setSnackbarOpen(true);
                    setSubmitLoading(false)
                    setSnackbarSeverity('error');
                    setTimeout(() => {
                        handleCloseAddModel()
                    }, 2000);
                }
            })
            .catch((error) => {
                console.error('Error uploading file:', error);
            })
            .finally(() => {
                setSubmitLoading(false)
                setTimeout(() => {
                    setSnackbarOpen(false);
                    handleCloseAddModel()
                }, 2000);
            });
    };

    const handleCloseIframe = () => {
        setIframeURL('')
        setIframeModal(false)
    }

    return (
        <Box>
            <Stack>
                {userData.role.id == 4 && <Button
                    onClick={() => {
                        handleOpenAddModel()
                    }}
                    variant="contained"
                    component={RouterLink}
                    to="#"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    sx={{ alignSelf: "end", marginBottom: 2 }}
                >
                    Add
                </Button>}
            </Stack>
            <Card>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <UserListHead headLabel={ASSESSOR_TABLE_HEAD} rowCount={list.length} />
                            <TableBody>
                                {list.map((row) => {
                                    const { id, attachment, title, created_at } = row;
                                    return (
                                        <TableRow
                                            style={{
                                                backgroundColor: 'transparent',
                                                cursor: 'default'
                                            }}
                                            key={id}
                                            tabIndex={-1}
                                            role="checkbox"
                                        >
                                            {/* DOCUMENTS TITLE */}
                                            <TableCell scope="row" padding="normal">
                                                <Typography variant="subtitle4" flexWrap={'wrap'}>
                                                    {title || 'N/A'}
                                                </Typography>
                                            </TableCell>

                                            <TableCell scope="row" padding="normal">
                                                <Typography variant="subtitle4" flexWrap={'wrap'}>
                                                    {moment(created_at).format('DD/MM/YYYY') || 'N/A'}

                                                </Typography>
                                            </TableCell>

                                            <TableCell scope="row" padding="normal">
                                                {attachment ? (
                                                    <Button
                                                        // target='blank'
                                                        size="small"
                                                        style={{
                                                            justifyContent: 'flex-start',
                                                            alignSelf: 'center',
                                                            marginRight: 5
                                                        }}
                                                        variant="outlined"
                                                        component={RouterLink}
                                                        // to={attachment}
                                                        onClick={() => {
                                                            setIframeModal(true)
                                                            setIframeURL(attachment)
                                                        }}
                                                        startIcon={
                                                            <Iconify color={pallete.primary.main} icon="carbon:view-filled" />
                                                        }
                                                    >
                                                        Click to see
                                                    </Button>
                                                ) : (
                                                    'N/A'
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            {
                                isDataNotFound && !loading && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={9}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                                                    <SearchNotFound message={`No Assessor Document Found`} />
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )
                            }
                        </Table>
                        {loading && (
                            <Box
                                py={5}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flex: 1
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        )}
                    </TableContainer>
                </Scrollbar>
            </Card>

            <Modal open={openModal} onClose={handleCloseAddModel} aria-labelledby="modal-title" aria-describedby="modal-description">
                <Box sx={style3}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Add Assesor Document
                    </Typography>

                    <Paper>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Title"
                                variant="outlined"
                                name="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                sx={{ mb: 2 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <input
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                id="file-input-bottom"
                                multiple
                            />
                            <label htmlFor="file-input-bottom">
                                <Button
                                    variant="outlined"
                                    component="span"
                                    fullWidth
                                    startIcon={<CloudUpload />}
                                >
                                    Upload File
                                </Button>
                            </label>
                        </Grid>
                        <Box display="flex" alignItems="center" mt={2}>
                            {file?.length !== 0 &&
                                <div style={{ marginTop: 10, flex: 1 }}>
                                    {file?.map((file, index) => (
                                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                            <Typography flex={1} textAlign={"center"} variant="body2" ml={2}>
                                                {file.name}
                                            </Typography>
                                            <IconButton sx={{ color: "red" }} aria-label="remove file" onClick={() => handleRemoveFile(file.name)}>
                                                <Close />
                                            </IconButton>
                                        </div>
                                    ))}
                                </div>
                            }
                        </Box>
                    </Paper>

                    <Divider />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                        <Button disabled={submitLoading} variant="contained" color="primary"
                            onClick={handleCloseAddModel}>
                            Cancel
                        </Button>
                        <LoadingButton loading={submitLoading} variant="contained" color="primary" sx={{ ml: 2 }}
                            onClick={handleAddDocuments}
                        >
                            Submit
                        </LoadingButton>
                    </Box>
                </Box>
            </Modal>

            <Iframe url={iframeURL} setDialogOpen={handleCloseIframe} dialogOpen={iframeModal} />

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </Box >
    );
}
