import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Grid, Chip, Alert, FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { api } from 'src/Api';
import MultiSelectDropDown from 'src/components/MultiSelectDropDown';
import { useTheme } from '@mui/material/styles';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const EQAsForm = ({ edit, selectedEQAs, handleClose, refreshPage }) => {
    console.log("🚀 ~ EQAsForm ~ selectedEQAs:", selectedEQAs)
    const theme = useTheme();
    const [formDataEQAs, setFormDataEQAs] = useState({
        id: null,
        surname: '',
        qualification: [],
        learner: [],
        email: '',
        accountExpiryDate: '',
        code: ''
    });

    console.log('formDataEQAs', formDataEQAs)

    const [error, setError] = useState({ visible: false, message: '' });

    const handleChange = (e) => {
        setError({ visible: false, message: '' });
        const { name, value } = e.target;
        setFormDataEQAs((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setFormDataEQAs((prevData) => ({
            ...prevData,
            qualification: typeof value === 'string' ? value.split(',') : value
        }));
    };

    const handleSelectChangeLearners = (event) => {
        const {
            target: { value }
        } = event;
        setFormDataEQAs((prevData) => ({
            ...prevData,
            learner: typeof value === 'string' ? value.split(',') : value
        }));
    };

    const [qualifications, setqualifications] = useState([]);
    const [loading, setLoading] = useState(false);

    function getQualifications() {
        const myHeaders = new Headers();

        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        // Append the user_id as a query parameter to the API endpoint URL
        const url = `${api.get_qualifications}?user_id=${user?.id}&company_admin_id=${user?.company_admin_id}`;

        fetch(url, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setqualifications(result.data);
                } else {
                    setqualifications([]);
                }
            })
            .catch((error) => console.log('error', error))
            .finally(() => { });
    }

    const [learners, setlearners] = useState([]);

    function getLearners() {
        const myHeaders = new Headers();

        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const checkQualifications = qualifications
            .filter((a) => formDataEQAs.qualification.includes(a.sub_title))
        const qualificationIds = checkQualifications.map((q) => q.id);

        // Append the user_id as a query parameter to the API endpoint URL
        const url = `${api.get_learners}?company_admin_id=${user?.company_admin_id}&qualifications=${JSON.stringify(qualificationIds)}`;

        fetch(url, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setlearners(result.data);
                } else {
                    setlearners([]);
                }
            })
            .catch((error) => console.log('error', error))
            .finally(() => { });
    }

    useEffect(() => {
        getQualifications();
        // getRefrences();
        // getLearners();
    }, []);

    useEffect(() => {
        if (edit) {
            setFormDataEQAs({
                surname: selectedEQAs?.user_qualifications[0]?.full_name || '',
                qualification: selectedEQAs?.user_qualifications[0]?.qualifications.map((e) => e.sub_title) || [],
                learner: selectedEQAs?.user_qualifications[0]?.learners.map((e) => e.full_name) || [],
                email: selectedEQAs?.email || '',
                accountExpiryDate: selectedEQAs?.user_qualifications[0]?.expiry_date || '',
            });
        }
    }, []);

    const appendIfNotEmpty = (formDataEQAs, key, value) => {
        if (value !== null && value !== undefined && value !== '') {
            formDataEQAs.append(key, value);
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);

        const checkQualifications = qualifications
            .filter((a) => formDataEQAs.qualification.includes(a.sub_title))

        const checkLearner = learners
            .filter((a) => formDataEQAs.learner.includes(a.full_name))

        const formdata = new FormData();

        if (edit) appendIfNotEmpty(formdata, 'id', selectedEQAs?.id);
        appendIfNotEmpty(formdata, 'role_id', '7');
        appendIfNotEmpty(formdata, 'sur_name', formDataEQAs.surname);
        appendIfNotEmpty(formdata, 'qualifications', JSON.stringify(checkQualifications));
        appendIfNotEmpty(formdata, 'learners', JSON.stringify(checkLearner));
        appendIfNotEmpty(formdata, 'email', formDataEQAs.email);
        appendIfNotEmpty(formdata, 'contact', formDataEQAs.contact);
        appendIfNotEmpty(formdata, 'sampling_ratio', formDataEQAs.samplingRatio);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(api.create_user, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 201 || status === 200) {
                    refreshPage();
                } else {
                    setError({ visible: true, message: result.error });
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <Box
            sx={{
                width: '100%',
                bgcolor: 'background.paper',
                p: 0,
                paddingTop: 2,
                paddingBottom: 0
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        disabled={edit}
                        fullWidth
                        variant="outlined"
                        label="Code"
                        name="Code"
                        value={selectedEQAs?.code}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={!edit}
                        fullWidth
                        label="Surname"
                        variant="outlined"
                        required
                        name="surname"
                        value={formDataEQAs.surname}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="qualification">Select Qualification/s</InputLabel>
                        <Select
                            labelId="qualification-label"
                            id="qualification"
                            multiple
                            value={formDataEQAs.qualification}
                            onChange={handleSelectChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Select Qualification/s" />}
                            MenuProps={MenuProps}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip label={value} />
                                    ))}
                                </Box>
                            )}
                        >
                            {qualifications.map((name) => (
                                <MenuItem
                                    key={name.id}
                                    value={name.sub_title}
                                    style={getStyles(name, formDataEQAs.qualification, theme)}
                                >
                                    {name.sub_title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {/* LEARNERS DROPDOWN */}
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="learner">Select Learner/s</InputLabel>
                        <Select
                            onOpen={getLearners}
                            labelId="learner-label"
                            id="learner"
                            multiple
                            value={formDataEQAs.learner}
                            onChange={handleSelectChangeLearners}
                            input={<OutlinedInput id="select-multiple-chip" label='Select Learner/s' />}
                            MenuProps={MenuProps}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip label={value} />
                                    ))}
                                </Box>
                            )}
                        >
                            {learners.map((name) => (
                                <MenuItem
                                    key={name.id}
                                    value={name.full_name}
                                    style={getStyles(name, formDataEQAs.learner, theme)}
                                >
                                    {name.full_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={!edit}
                        fullWidth
                        type="email"
                        label="Email ID"
                        variant="outlined"
                        name="email"
                        value={formDataEQAs.email}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        type="date"
                        label="Account Expiry Date"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        name="accountExpiryDate"
                        value={formDataEQAs.accountExpiryDate}
                        onChange={handleChange}
                    />
                </Grid>

                {error?.visible && (
                    <Grid item xs={12}>
                        <Alert style={{ marginBottom: 20 }} severity="error">
                            {error?.message}
                        </Alert>
                    </Grid>
                )}
                {edit && (
                    <Grid item xs={12}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                                handleSubmit(formDataEQAs);
                            }}
                            loading={loading}
                        >
                            Submit
                        </LoadingButton>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default EQAsForm;
