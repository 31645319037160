import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    CircularProgress,
    Pagination,
    IconButton,
    Checkbox,
    Modal,
    Button,
    Drawer,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    Chip,
    MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead } from '../../sections/@dashboard/user';
import { Close as CloseIcon, UploadFile as UploadFileIcon, Delete as DeleteIcon, CloudUpload as CloudUploadIcon, ArrowBack, } from '@mui/icons-material';
import { api } from '../../Api';
import { useTranslation } from 'react-i18next';
import { ArrowBacks } from '@mui/icons-material';
import EditBulkLearnersForm from 'src/components/Users/EditBulkLearnerForm';
import { useTheme } from '@mui/material/styles';
import pallete from '../../theme/palette';
import moment from 'moment';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function Registered_Learner() {
    const theme = useTheme();
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem('user'));

    const LEARNER_TABLE_HEAD = [
        { id: 'full_name', label: 'Name', alignRight: false },
        { id: 'qualification', label: 'Qualification', alignRight: false },
        { id: 'reference', label: 'Reference', alignRight: false },
        { id: 'assessor', label: 'Assessor', alignRight: false },
        { id: 'iqa', label: 'IQA', alignRight: false },
        { id: 'completion', label: 'Completion', alignRight: false },
        { id: 'cohort', label: 'Cohort/Batch', alignRight: false },
        { id: 'select', label: 'Select', alignRight: false }
    ];

    const ASSESSORS_TABLE_HEAD = [
        { id: 'full_name', label: 'Name', alignRight: false },
        { id: 'progress', label: 'Progress', alignRight: false },
        { id: 'status', label: 'Status', alignRight: false },
        { id: 'iqa', label: 'IQA Activity', alignRight: false },
    ];

    const IQA_TABLE_HEAD = [
        { id: 'full_name', label: 'Name', alignRight: false },
        { id: 'progress', label: 'Progress', alignRight: false },
        { id: 'assessor', label: 'Assessor', alignRight: false },
        { id: 'sampling_ratio ', label: 'Sampling ratio', alignRight: false },
        { id: 'Sampling_%age', label: 'Sampling %age', alignRight: false },
    ];

    const EQA_TABLE_HEAD = [
        { id: 'full_name', label: 'Name', alignRight: false },
        { id: 'cate of completion', label: 'Date of Completion', alignRight: false },
        { id: 'assessor', label: 'Assessor', alignRight: false },
        { id: 'Sampling_%age', label: 'Sampling %age', alignRight: false },
    ];

    const ASSESSORS_LIST_TABLE_HEAD = [
        { id: 'Learner Name ', label: 'Learner Name ', alignRight: false },
        { id: 'progress', label: 'Progress', alignRight: false },
        { id: 'Qualification/Subject', label: 'Qualification/Subject', alignRight: false },
        { id: 'Sampling Requirement', label: 'Sampling Requirement', alignRight: false },
        { id: 'Sampling Conducted', label: 'Sampling Conducted', alignRight: false },
    ];

    const [permission, setpermission] = useState([]);
    const requirement = ['visible', 'edit', 'change status', 'change password'];

    useEffect(() => {
        if (userData.role.id == 4 || userData.role.id == 5 || userData.role.id == 7) {
            getQualificationsById()
        }
    }, [userData])

    function checkRender() {
        let check = 'users';
        const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
            (item) => item.permission_name.toLowerCase() == check
        );

        localPerm[0]?.permission_types?.filter((items) =>
            requirement.includes(items.permission_type_name.toLowerCase())
                ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
                : false
        );
    }

    useEffect(() => {
        checkRender();
    }, []);

    useEffect(() => {
        getUsers();
    }, []);

    const [openUser, setOpenUser] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [loadingUserDropDown, setLoadingUserDropDown] = useState(false);
    const [qualifications, setQualification] = useState([]);
    const [reference, setReference] = useState([]);
    const [assessors, setAssessors] = useState([]);
    const [IQAs, setIQAs] = useState([]);
    const [batchs, setBatch] = useState([]);
    const [selectedName, setSelectedName] = useState([]);
    const [userName, setUserName] = useState([]);
    const [selectedQualifications, setSelectedQualifications] = useState([]);
    const [selectedReference, setSelectedReference] = useState([]);
    const [selectedAssessors, setSelectedAssessors] = useState([]);
    const [selectedIQAs, setSelectedIQAs] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState([]);
    const [qualificationTitle, setQualificationTitle] = useState('');
    const [qualificationNumber, setQualificationNumber] = useState('');

    const handleCloseBulkModal = () => setOpenUser(false);

    function openBulkEditModal() {
        setOpenUser(true);
    }

    const [list, setlist] = useState([]);
    const [listAssessors, setlistAssessors] = useState([]);
    const [search, setsearch] = useState('');
    const [loading, setloading] = useState(true);
    const [loadingAssessor, setloadingAssessor] = useState(true);
    const [searchLoading, setsearchLoading] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [pagesCount2, setpagesCount2] = useState(1);
    const [page, setPage] = useState(1);
    const [page2, setPage2] = useState(1);
    const [draweLoading, setDraweLoading] = useState(false);
    const [errorUserDropDown, setErrorUserDropDown] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModalList, setOpenModalList] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState('');
    const [selectedAssessorList, setSelectedAssessorList] = useState('');

    function getQualificationsById() {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));

        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        const queryParams = new URLSearchParams();
        queryParams.append('company_admin_id', user?.company_admin_id);
        queryParams.append('id', id);

        // Append the user_id as a query parameter to the API endpoint URL
        const url = `${api.get_qualifications}?${queryParams.toString()}`;
        fetch(url, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setQualificationTitle(result.data[0]?.sub_title)
                    setQualificationNumber(result.data[0]?.sub_number)
                }
            })
            .catch((error) => console.log('error', error))
            .finally(() => { });
    }

    const handlePageChange = (event, value) => {
        list.length = 0;
        setPage(value);
        getUsers(value);
    };

    const handlePageChange2 = (event, value) => {
        listAssessors.length = 0;
        setPage2(value);
        getAssessorsList(value);
    };

    function getUsers(page) {
        setloading(true);
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        myHeaders.append('Authorization', `Bearer ${token}`);

        const nameCheck = userName
            .filter((a) => selectedName.includes(a.full_name))
            .map((a) => a.id);
        const QualificationCheck = qualifications
            .filter((a) => selectedQualifications.includes(a.sub_title))
            .map((a) => a.id);
        const AssessorCheck = assessors
            .filter((a) => selectedAssessors.includes(a.full_name))
            .map((a) => a.id);
        const IQAsCheck = IQAs
            .filter((a) => selectedIQAs.includes(a.full_name))
            .map((a) => a.id);
        const BatchCheck = qualifications
            .filter((a) => selectedQualifications.includes(a.cohort_batch_no))
            .map((a) => a.cohort_batch_no);

        // Construct the query string
        const queryParams = new URLSearchParams();
        search.length > 0 && queryParams.append('search', search);
        page && queryParams.append('page', page);
        queryParams.append('role_id', 3);
        queryParams.append('company_admin_id', user?.company_admin_id);
        id && queryParams.append('qualification_id', id);
        QualificationCheck.length !== 0 && queryParams.append('qualification_ids', QualificationCheck.join(','));
        AssessorCheck.length !== 0 && queryParams.append('assessor_ids', AssessorCheck.join(','));
        IQAsCheck.length !== 0 && queryParams.append('iqa_ids', IQAsCheck.join(','));
        selectedReference.length !== 0 && queryParams.append('ref_numbers', selectedReference.join(','));
        BatchCheck.length !== 0 && queryParams.append('batch_numbers', BatchCheck.join(','));
        nameCheck.length !== 0 && queryParams.append('name_ids', nameCheck.join(','));

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${api.getUsers}?${queryParams.toString()}`, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setlist(result.data);
                    result?.count ? setpagesCount(Math.ceil(result?.count / 10)) : setpagesCount(1);
                } else {
                    setlist([]);
                    setpagesCount(0);
                }
            })
            .catch((error) => console.log('error', error))
            .finally(() => {
                setloading(false);
                setsearchLoading(false);
            });
    }

    function getAssessorsList(page, assessor_id) {
        setloadingAssessor(true);
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        myHeaders.append('Authorization', `Bearer ${token}`);
        // Construct the query string
        const queryParams = new URLSearchParams();
        search.length > 0 && queryParams.append('search', search);
        page && queryParams.append('page', page);
        queryParams.append('role_id', 3);
        queryParams.append('company_admin_id', user?.company_admin_id);
        id && queryParams.append('qualification_id', id);
        assessor_id && queryParams.append('assessor_id', assessor_id ? assessor_id : selectedAssessorList);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${api.getUsers}?${queryParams.toString()}`, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setlistAssessors(result.data);
                    result?.count ? setpagesCount2(Math.ceil(result?.count / 10)) : setpagesCount2(1);
                } else {
                    setlistAssessors([]);
                    setpagesCount2(0);
                }
            })
            .catch((error) => console.log('error', error))
            .finally(() => {
                setloadingAssessor(false);
            });
    }

    const isUserNotFound = list.length === 0;
    const isAssessorNotFound = listAssessors.length === 0;

    useEffect(() => {
        if (search.length == 0) {
            list.length = 0;
            getUsers(page);
        }
    }, [search]);

    const [selected, setSelected] = useState([]);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = list.map((n) => n.user_qualifications[0]?.id);
            setSelected(newSelecteds);
            return;
        } else {
            setSelected([]);
        }
    };

    const handleSelect = (id) => {
        if (selected.includes(id)) {
            // Remove the ID from the array
            setSelected(selected.filter((item) => item !== id));
        } else {
            // Add the ID to the array
            setSelected([...selected, id]);

        }
    };

    const Table_Row = ({ row }) => {
        const {
            id,
            full_name,
            seen,
            qualifications,
            ref_number,
            assessors,
            iqas,
            completion,
            cohort_batch_no,
            user_qualifications
        } = row;
        console.log("🚀 ~ Registered_Learner ~ row:", row)
        if (userData.role.id == 4) {
            return (
                <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                    role="checkbox"
                    // className="highlighted-row"
                    sx={{
                        backgroundColor: user_qualifications[0]?.disability == 1 ? 'rgba(255, 54, 1, 0.3)' : 'transparent',
                    }}
                >
                    {/* NAME */}
                    <TableCell padding="normal" style={{ width: 150 }}>
                        <RouterLink className="routerLink" to={
                            userData.role.id == 4 ?
                                `/dashboard/qualifications/qualificationDetails/${row?.id}/${user_qualifications[0]?.qualifications_with_trash[0]?.id}`
                                : `/dashboard/users/learnerDetails/${row?.id}/${user_qualifications[0]?.qualifications_with_trash[0]?.id}`}>
                            <Typography style={{ width: 150 }} variant="subtitle2" flexWrap={'wrap'}>
                                {full_name === 'undefined' ? 'N/A' : full_name}
                            </Typography>
                        </RouterLink>
                    </TableCell>

                    {/* PROGRESS */}
                    <TableCell align="left" style={{ width: 80 }}>
                        <Typography flexWrap={'wrap'} style={{ width: 80 }}>
                            {user_qualifications[0]?.progress || 'N/A'}
                        </Typography>
                    </TableCell>

                    {/* STATUS */}
                    <TableCell align="left" style={{ width: 120 }}>
                        <Typography
                            style={{
                                textTransform: 'capitalize',
                                fontWeight: (user_qualifications[0]?.learner_Status?.toLowerCase() === 'accepted' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'awaiting submission' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'awaiting assessment' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'resubmission required') ? 'bold' : " 500",
                                fontSize: 12,
                                backgroundColor:
                                    user_qualifications[0]?.learner_Status?.toLowerCase() === 'accepted' && "rgba(84, 214, 44, 0.16)" ||
                                    user_qualifications[0]?.learner_Status?.toLowerCase() === 'awaiting submission' && "rgba(24, 144, 255, 0.16)" ||
                                    user_qualifications[0]?.learner_Status?.toLowerCase() === 'awaiting assessment' && "rgba(255, 148, 112, 0.16)" ||
                                    user_qualifications[0]?.learner_Status?.toLowerCase() === 'resubmission required' && "rgba(168, 168, 165, 0.3)",
                                paddingLeft: (user_qualifications[0]?.learner_Status?.toLowerCase() === 'accepted' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'awaiting submission' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'awaiting assessment' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'resubmission required') && 5,
                                paddingRight: (user_qualifications[0]?.learner_Status?.toLowerCase() === 'accepted' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'awaiting submission' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'awaiting assessment' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'resubmission required') && 5,
                                paddingTop: (user_qualifications[0]?.learner_Status?.toLowerCase() === 'accepted' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'awaiting submission' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'awaiting assessment' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'resubmission required') && 6,
                                paddingBottom: (user_qualifications[0]?.learner_Status?.toLowerCase() === 'accepted' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'awaiting submission' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'awaiting assessment' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'resubmission required') && 6,
                                textAlign: (user_qualifications[0]?.learner_Status?.toLowerCase() === 'accepted' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'awaiting submission' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'awaiting assessment' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'resubmission required') && "center",
                                borderRadius: (user_qualifications[0]?.learner_Status?.toLowerCase() === 'accepted' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'awaiting submission' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'awaiting assessment' || user_qualifications[0]?.learner_Status?.toLowerCase() === 'resubmission required') && 7,
                                color:
                                    user_qualifications[0]?.learner_Status?.toLowerCase() === 'accepted' && "#54D62C" ||
                                    user_qualifications[0]?.learner_Status?.toLowerCase() === 'awaiting submission' && "#1890FF" ||
                                    user_qualifications[0]?.learner_Status?.toLowerCase() === 'awaiting assessment' && "orange" ||
                                    user_qualifications[0]?.learner_Status?.toLowerCase() === 'resubmission required' && "#969695" || "black"
                            }}
                        >
                            {user_qualifications[0]?.learner_Status || 'N/A'}
                        </Typography>
                    </TableCell>

                    {/* IQA ACTIVITY */}
                    {/* <TableCell align="left" style={{ width: 80 }}>
                        {user_qualifications[0]?.iqa_activity ?
                            <Button
                                size="small"
                                onClick={() => handleModalOpen(user_qualifications[0]?.iqa_activity)}
                                style={{
                                    justifyContent: 'flex-start',
                                    alignSelf: 'center',
                                    marginRight: 5
                                }}
                                variant="outlined"
                                component={RouterLink}
                                to="#"
                                startIcon={
                                    <Iconify color={pallete.primary.main} icon="carbon:view-filled" />
                                }
                            >
                                Click to see
                            </Button>
                            :
                            'N/A'
                        }
                    </TableCell> */}
                    <TableCell align="left" style={{ width: 80 }}>
                        <Typography flexWrap={'wrap'} style={{ width: 80 }}>
                            {user_qualifications[0]?.sampling_age || 'N/A'}
                        </Typography>
                    </TableCell>

                </TableRow>
            )
        } else if (userData.role.id == 5) {
            return (
                <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                    role="checkbox"
                    sx={{
                        backgroundColor: user_qualifications[0]?.disability == 1 ? 'rgba(255, 54, 1, 0.3)' : 'transparent',
                    }}
                >
                    <TableCell padding="normal" style={{ width: 150 }}>
                        <Typography style={{ width: 150 }} variant="subtitle2" flexWrap={'wrap'}>
                            {full_name === 'undefined' ? 'N/A' : full_name}
                        </Typography>
                    </TableCell>

                    {/* PROGRESS */}
                    <TableCell align="left" style={{ width: 80 }}>
                        <Typography flexWrap={'wrap'} style={{ width: 80 }}>
                            {user_qualifications[0]?.progress || 'N/A'}
                        </Typography>
                    </TableCell>

                    {/* ASSESSORS */}
                    <TableCell align="left" style={{ maxWidth: 150 }}>
                        <RouterLink className='routerLink' onClick={() => handleModalOpenList(user_qualifications[0]?.assessors_with_trash[0]?.id)}>
                            <Typography style={{ width: 150 }} variant="subtitle2" flexWrap={'wrap'}>
                                {user_qualifications[0]?.assessors[0]?.full_name || 'N/A'}
                            </Typography>
                        </RouterLink>
                    </TableCell>

                    {/* SAMPLING RATIO */}
                    <TableCell align="left" style={{ width: 150 }}>
                        <Typography flexWrap={'wrap'}>
                            {user_qualifications[0]?.assessors_with_trash[0]?.user_qualifications[0]?.sampling_ratio ?
                                `${user_qualifications[0]?.assessors_with_trash[0]?.user_qualifications[0]?.sampling_ratio}%` :
                                "0%"}
                        </Typography>
                    </TableCell>

                    {/* SAMPLING AGE */}
                    <TableCell align="left" style={{ width: 150 }}>
                        <Typography flexWrap={'wrap'}>
                            {user_qualifications[0]?.sampling_age ?
                                `${user_qualifications[0]?.sampling_age}` :
                                "0%"}
                        </Typography>
                    </TableCell>
                </TableRow>
            )
        } else if (userData.role.id == 7) {
            return (
                <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                    role="checkbox"
                >
                    <TableCell padding="normal" style={{ maxWidth: 150 }}>
                        <RouterLink
                            className='routerLink'
                            to={`/dashboard/qualifications/qualificationDetails/${row?.id}/${user_qualifications[0]?.qualifications_with_trash[0]?.id}`}
                        >
                            <Typography style={{ width: 150 }} variant="subtitle2" flexWrap={'wrap'}>
                                {full_name === 'undefined' ? 'N/A' : full_name}
                            </Typography>
                        </RouterLink>
                    </TableCell>

                    {/* Completion Date */}
                    <TableCell align="left" style={{ maxWidth: 120 }}>
                        <Typography flexWrap={'wrap'} style={{ width: 120 }}>
                            {user_qualifications[0]?.completion_date !== null ? moment(user_qualifications[0]?.completion_date).format('DD/MM/YYYY') : 'N/A'}
                        </Typography>
                    </TableCell>

                    {/* ASSESSORS */}
                    <TableCell align="left" style={{ maxWidth: 120 }}>
                        <Typography noWrap variant="subtitle2" flexWrap={'wrap'}>
                            {user_qualifications[0]?.assessors[0]?.full_name || 'N/A'}
                        </Typography>
                    </TableCell>

                    {/* SAMPLING AGE */}
                    <TableCell align="left" style={{ maxWidth: 150 }}>
                        <Typography flexWrap={'wrap'}>
                            {`${user_qualifications[0]?.assessors_with_trash[0]?.user_qualifications[0]?.sampling_age}` || "0%"}
                        </Typography>
                    </TableCell>
                </TableRow>
            )
        } else {
            return (
                <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                    role="checkbox"
                    sx={{
                        backgroundColor: user_qualifications[0]?.disability == 1 ? 'rgba(255 ,54, 1, 0.3)' : 'transparent',
                    }}
                >
                    {/* NAME */}
                    <TableCell padding="normal" style={{ width: 150 }}>
                        <RouterLink className="routerLink" to={`/dashboard/users/learnerDetails/${row?.id}/${user_qualifications[0]?.qualifications_with_trash[0]?.id}`}>
                            <Typography style={{ width: 150 }} variant="subtitle2" flexWrap={'wrap'}>
                                {full_name === 'undefined' ? 'N/A' : full_name}
                            </Typography>
                        </RouterLink>
                    </TableCell>

                    {/* QUALIFICATION */}
                    <TableCell align="left" style={{ width: 150 }}>
                        <Typography style={{ width: 150 }} variant="subtitle2" flexWrap={'wrap'}>
                            {user_qualifications[0]?.qualifications[0]?.sub_title || 'N/A'}
                        </Typography>
                    </TableCell>

                    {/* REF NUMBER */}
                    <TableCell align="left" style={{ width: 50 }}>
                        <Typography flexWrap={'wrap'}>{user_qualifications[0]?.ref_number || 'N/A'}</Typography>
                    </TableCell>

                    {/* ASSESSORS */}
                    <TableCell align="left" style={{ maxWidth: 80 }}>
                        <Typography flexWrap={'wrap'}>
                            {user_qualifications[0]?.assessors[0]?.full_name || 'N/A'}
                        </Typography>
                    </TableCell>

                    {/* IQAs */}
                    <TableCell align="left" style={{ width: 80 }}>
                        <Typography flexWrap={'wrap'} style={{ width: 80 }}>
                            {user_qualifications[0]?.iqas[0]?.full_name || 'N/A'}
                        </Typography>
                    </TableCell>

                    {/* COMPLETION */}
                    <TableCell align="left" style={{ width: 50 }}>
                        <Typography flexWrap={'wrap'}>{user_qualifications[0]?.completion || 'N/A'}</Typography>
                    </TableCell>

                    {/* COHORT BATCH NUMBER */}
                    <TableCell align="left" style={{ width: 50 }}>
                        <Typography flexWrap={'wrap'}>
                            {user_qualifications[0]?.cohort_batch_no || 'N/A'}
                        </Typography>
                    </TableCell>

                    <TableCell align="left" style={{ maxWidth: 130 }}>
                        <Checkbox
                            checked={selected.includes(user_qualifications[0]?.id)}
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent the click event from bubbling up to the row
                                handleSelect(user_qualifications[0]?.id);
                            }}
                        />
                    </TableCell>
                </TableRow>
            );
        }
    };

    const style = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        width: '100%',
        height: '80%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
        border: '2px solid',
        borderColor: 'primary.main',
        overflow: 'scroll'
    };

    const refreshAdmin = () => {
        setOpenUser(false);
        list.length = 0;
        getUsers(page);
    };

    function getUsersDropdown() {
        setLoadingUserDropDown(true)

        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const userData = JSON.parse(localStorage.getItem('user'));
        myHeaders.append('Authorization', `Bearer ${token}`);
        // Construct the query string

        const queryParams = new URLSearchParams();
        queryParams.append('company_admin_id', userData?.company_admin_id);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${api.getUsers}?${queryParams.toString()}`, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setUserName(result.data);
                    setLoadingUserDropDown(false)
                } else {
                    setLoadingUserDropDown(false)
                    setErrorUserDropDown(true)
                    setUserName([]);
                }
            })
            .catch((error) => {
                console.log('error', error)
                setLoadingUserDropDown(false)
                setErrorUserDropDown(true)
            })
            .finally(() => {

            });
    }

    const getQualifications = () => {
        setLoadingUserDropDown(true)

        const token = localStorage.getItem('token');
        const userData = JSON.parse(localStorage.getItem('user'));
        fetch(`${api.get_qualifications}?company_admin_id=${userData?.company_admin_id}`, {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token}`
            })
        })
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setQualification(result.data);
                    setLoadingUserDropDown(false)
                } else {
                    setLoadingUserDropDown(false)
                    setErrorUserDropDown(true)
                    setQualification([]);
                }
            })
            .catch((error) => {
                console.log('error', error)
                setErrorUserDropDown(true)
                setLoadingUserDropDown(false)
            });
    };

    const getReference = () => {
        setLoadingUserDropDown(true)

        const token = localStorage.getItem('token');
        const userData = JSON.parse(localStorage.getItem('user'));
        fetch(`${api.get_references}?company_admin_id=${userData?.company_admin_id}`, {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token}`
            })
        })
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setReference(result.data);
                    setLoadingUserDropDown(false)
                } else {
                    setReference([]);
                    setLoadingUserDropDown(false)
                    setErrorUserDropDown(true)
                }
            })
            .catch((error) => {
                setLoadingUserDropDown(false)
                setErrorUserDropDown(true)
                console.log('error', error)
            });
    };

    const ClearFilter = () => {
        setSelectedName([])
        setSelectedQualifications([])
        setSelectedReference([])
        setSelectedAssessors([])
        setSelectedIQAs([])
        setSelectedBatch([])

        if (
            selectedName.length == 0 &&
            selectedQualifications.length == 0 &&
            selectedReference.length == 0 &&
            selectedAssessors.length == 0 &&
            selectedIQAs.length == 0 &&
            selectedBatch.length == 0
        ) {
            setOpenDrawer(false);
            refreshAdmin()
        }
    }

    const getAssessors = () => {

        setLoadingUserDropDown(true)

        const token = localStorage.getItem('token');
        const userData = JSON.parse(localStorage.getItem('user'));
        fetch(`${api.get_assessors}?company_admin_id=${userData?.company_admin_id}`, {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token}`
            })
        })
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setAssessors(result.data);
                    setLoadingUserDropDown(false)
                } else {
                    setAssessors([]);
                    setLoadingUserDropDown(false)
                    setErrorUserDropDown(true)
                }
            })
            .catch((error) => {
                console.log('error', error)
                setLoadingUserDropDown(false)
                setErrorUserDropDown(true)
            });
    };

    const getIQAs = () => {

        setLoadingUserDropDown(true)

        const token = localStorage.getItem('token');
        const userData = JSON.parse(localStorage.getItem('user'));
        fetch(`${api.get_iqas}?company_admin_id=${userData?.company_admin_id}`, {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token}`
            })
        })
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setIQAs(result.data);
                    setLoadingUserDropDown(false)
                } else {
                    setIQAs([]);
                    setLoadingUserDropDown(false)
                    setErrorUserDropDown(true)
                }
            })
            .catch((error) => {
                console.log('error', error);
                setLoadingUserDropDown(false);
                setErrorUserDropDown(true);
            });
    };

    const getBatch = () => {
        setLoadingUserDropDown(true)
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const userData = JSON.parse(localStorage.getItem('user'));
        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${api.get_batch_no}?company_admin_id=${userData?.company_admin_id}`, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setBatch(result.data);
                    setLoadingUserDropDown(false);
                } else {
                    setBatch([]);
                    setLoadingUserDropDown(false);
                    setErrorUserDropDown(true);
                }
            })
            .catch((error) => {
                console.log('error', error);
                setLoadingUserDropDown(false);
                setErrorUserDropDown(true);
            });
    };

    const handleChangeQualifications = (event) => {
        const value = event.target.value;
        setSelectedQualifications(typeof value === 'string' ? value.split(',') : value);
    };

    const handleChangeReference = (event) => {
        const value = event.target.value;
        setSelectedReference(typeof value === 'string' ? value.split(',') : value);
    };

    const handleChangeAssessors = (event) => {
        const value = event.target.value;
        setSelectedAssessors(typeof value === 'string' ? value.split(',') : value);
    };

    const handleChangeIQAs = (event) => {
        const value = event.target.value;
        setSelectedIQAs(typeof value === 'string' ? value.split(',') : value);
    };

    const handleChangeBatchs = (event) => {
        const value = event.target.value;
        setSelectedBatch(typeof value === 'string' ? value.split(',') : value);
    };

    const handleChangeUserName = (event) => {
        const value = event.target.value;
        setSelectedName(typeof value === 'string' ? value.split(',') : value);
    };

    const handleModalOpen = (message) => {
        setSelectedMessage(message);
        setOpenModal(true);
    };

    const handleModalOpenList = (id) => {
        getAssessorsList(page, id)
        setSelectedAssessorList(id);
        setOpenModalList(true);
    };

    const handleModalClose = () => {
        setOpenModal(false);
        setSelectedMessage('');
    };

    const handleModalCloseList = () => {
        setOpenModalList(false);
        setlistAssessors([])
    };

    const style3 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: '500px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 1,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        outline: 0,
    };

    return (
        <Page title={`Users | ${process.env.REACT_APP_PROJECTNAME}`}>
            <Container>


                {/*Bulk Edit Modal */}
                <Modal
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%'
                    }}
                    open={openUser}
                    onClose={handleCloseBulkModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            p: 4,
                            justifyContent: 'center',
                            maxWidth: '70%',
                            maxHeight: '90%',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            overflow: 'auto',
                            border: '2px solid',
                            borderColor: 'primary.main',
                            flex: 1,
                            style
                        }}
                    >
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                Bulk Edit Learner
                            </Typography>
                            <IconButton
                                onClick={() => {
                                    setOpenUser(false);
                                }}
                                edge="end"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <EditBulkLearnersForm
                            selectedIds={selected}
                            handleClose={handleCloseBulkModal}
                            refreshAdmin={refreshAdmin}
                        />
                    </Box>
                </Modal>

                <Drawer
                    classes={{
                        paper: 'width_drawer'
                    }}
                    anchor={'right'}
                    open={openDrawer}
                    onClose={() => {
                        setOpenDrawer(false);
                    }}
                >
                    <Box pb={2} className="cross_drawer">
                        <IconButton
                            aria-label="close"
                            style={{
                                position: 'absolute',
                                right: 5,
                                marginBottom: 10,
                                marginRight: 10
                            }}
                            onClick={() => {
                                setOpenDrawer(false);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    {draweLoading ?
                        <Box
                            py={5}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flex: 1
                            }}
                        >
                            <CircularProgress />
                        </Box>
                        :
                        <>
                            < Box
                                mt={3}
                                p={1}
                                style={{
                                    flexDirection: 'row',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '90%',
                                    alignSelf: 'center'
                                }}
                            >
                                {/* Qualification */}
                                <FormControl sx={{ mb: 2 }} fullWidth variant="outlined">
                                    <InputLabel id="qualification-label">Select Qualification/s</InputLabel>
                                    <Select
                                        onOpen={getQualifications}
                                        labelId="qualification-label"
                                        id="qualification"
                                        multiple
                                        value={selectedQualifications}
                                        onChange={handleChangeQualifications}
                                        input={<OutlinedInput id="select-multiple-chip" label="Select Qualification/s" />}
                                        MenuProps={MenuProps}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip label={value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        {loadingUserDropDown ? (
                                            <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                                                <CircularProgress size={24} color="inherit" />
                                            </MenuItem>
                                        ) : errorUserDropDown ? (
                                            <MenuItem disabled>No data found</MenuItem>
                                        ) : (
                                            qualifications.map((name) => (
                                                <MenuItem
                                                    key={name.id}
                                                    value={name.sub_title}
                                                    style={getStyles(name, selectedQualifications, theme)}
                                                >
                                                    {name.sub_title}
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </FormControl>

                                {/* Assessor */}
                                <FormControl sx={{ mb: 2 }} fullWidth variant="outlined">
                                    <InputLabel id="Assessor-label">Select Assessor</InputLabel>
                                    <Select
                                        onOpen={getAssessors}
                                        labelId="Assessor-label"
                                        id="Assessor"
                                        multiple
                                        value={selectedAssessors}
                                        onChange={handleChangeAssessors}
                                        input={<OutlinedInput id="select-multiple-chip" label="Select Assessor" />}
                                        MenuProps={MenuProps}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip label={value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        {loadingUserDropDown ? (
                                            <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                                                <CircularProgress size={24} sx={{ color: "#000000" }} />
                                            </MenuItem>
                                        ) : errorUserDropDown ? (
                                            <MenuItem disabled>No data found</MenuItem>
                                        ) : (
                                            assessors.map((name) => (
                                                <MenuItem
                                                    key={name.id}
                                                    value={name.full_name}
                                                    style={getStyles(name, selectedAssessors, theme)}
                                                >
                                                    {name.full_name}
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </FormControl>

                                {/* IQA */}
                                <FormControl sx={{ mb: 2 }} fullWidth variant="outlined">
                                    <InputLabel id="IQAs-label">Select IQAs</InputLabel>
                                    <Select
                                        onOpen={getIQAs}
                                        labelId="IQAs-label"
                                        id="IQAs"
                                        multiple
                                        value={selectedIQAs}
                                        onChange={handleChangeIQAs}
                                        input={<OutlinedInput id="select-multiple-chip" label="Select IQAs" />}
                                        MenuProps={MenuProps}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip label={value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        {loadingUserDropDown ? (
                                            <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                                                <CircularProgress size={24} sx={{ color: "#000000" }} />
                                            </MenuItem>
                                        ) : errorUserDropDown ? (
                                            <MenuItem disabled>No data found</MenuItem>
                                        ) : (
                                            IQAs.map((name) => (
                                                <MenuItem
                                                    key={name.id}
                                                    value={name.full_name}
                                                    style={getStyles(name, selectedIQAs, theme)}
                                                >
                                                    {name.full_name}
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </FormControl>

                                {/* Name */}
                                <FormControl sx={{ mb: 2 }} fullWidth variant="outlined">
                                    <InputLabel id="Name-label">Name</InputLabel>
                                    <Select
                                        onOpen={getUsersDropdown}
                                        labelId="Name-label"
                                        id="Name"
                                        multiple
                                        value={selectedName}
                                        onChange={handleChangeUserName}
                                        input={<OutlinedInput id="select-multiple-chip" label="Name" />}
                                        MenuProps={MenuProps}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip label={value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        {loadingUserDropDown ? (
                                            <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                                                <CircularProgress size={24} sx={{ color: "#000000" }} />
                                            </MenuItem>
                                        ) : errorUserDropDown ? (
                                            <MenuItem disabled>No data found</MenuItem>
                                        ) : (
                                            userName.map((name) => (
                                                <MenuItem
                                                    key={name.id}
                                                    value={name.full_name}
                                                    style={getStyles(name, selectedName, theme)}
                                                >
                                                    {name.full_name}
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </FormControl>

                                {/* Reference */}
                                <FormControl sx={{ mb: 2 }} fullWidth variant="outlined">
                                    <InputLabel id="Reference-label">Select Reference</InputLabel>
                                    <Select
                                        onOpen={getReference}
                                        labelId="Reference-label"
                                        id="Reference"
                                        multiple
                                        value={selectedReference}
                                        onChange={handleChangeReference}
                                        input={<OutlinedInput id="select-multiple-chip" label="Select Reference" />}
                                        MenuProps={MenuProps}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip label={value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        {loadingUserDropDown ? (
                                            <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                                                <CircularProgress size={24} sx={{ color: "#000000" }} />
                                            </MenuItem>
                                        ) : errorUserDropDown ? (
                                            <MenuItem disabled>No data found</MenuItem>
                                        ) : (
                                            reference.map((name) => (
                                                <MenuItem
                                                    key={name.user_qualifications[0].ref_number}
                                                    value={name.user_qualifications[0].ref_number}
                                                    style={getStyles(name, selectedReference, theme)}
                                                >
                                                    {name.user_qualifications[0].ref_number}
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </FormControl>

                                {/* Cohort/Batch */}
                                <FormControl sx={{ mb: 2 }} fullWidth variant="outlined">
                                    <InputLabel id="Cohort/Batch-label">Select Cohort/Batch</InputLabel>
                                    <Select
                                        onOpen={getBatch}
                                        labelId="Cohort/Batch-label"
                                        id="Cohort/Batch"
                                        multiple
                                        value={selectedBatch}
                                        onChange={handleChangeBatchs}
                                        input={<OutlinedInput id="select-multiple-chip" label="Select Cohort/Batch" />}
                                        MenuProps={MenuProps}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip label={value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        {loadingUserDropDown ? (
                                            <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                                                <CircularProgress size={24} sx={{ color: "#000000" }} />
                                            </MenuItem>
                                        ) : errorUserDropDown ? (
                                            <MenuItem disabled>No data found</MenuItem>
                                        ) : (
                                            batchs.map((name) => (
                                                <MenuItem
                                                    key={name.cohort_batch_no}
                                                    value={name.cohort_batch_no}
                                                    style={getStyles(name, selectedBatch, theme)}
                                                >
                                                    {name.cohort_batch_no}
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box style={{ width: '90%', alignSelf: 'center', marginTop: 20, marginBottom: 20 }}>
                                <Button
                                    onClick={() => {
                                        ClearFilter()
                                    }}
                                    variant="outlined"
                                    component={RouterLink}
                                    to="#"
                                    fullWidth
                                    style={{ height: 50, marginBottom: 15 }}
                                >
                                    Clear All
                                </Button>
                                <Button
                                    onClick={(event) => {
                                        // list.length = 0;
                                        setOpenDrawer(false);
                                        setPage(1);
                                        getUsers(1);
                                    }}
                                    variant="contained"
                                    component={RouterLink}
                                    to="#"
                                    fullWidth
                                    style={{ height: 50 }}
                                >
                                    Apply
                                </Button>
                            </Box>
                        </>
                    }
                </Drawer>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <IconButton onClick={() => navigate(-1)}>
                        <ArrowBack />
                    </IconButton>
                    <Typography sx={{ flex: 1, marginTop: 1.2, marginLeft: 2 }} variant="h4" gutterBottom>
                        {userData?.role?.id == 7 ? "Learners Assigned" : "Registered Learners"}
                    </Typography>
                    {userData.role.id == 2 &&
                        <>
                            <Button
                                disabled={selected.length == 0}
                                onClick={() => {
                                    openBulkEditModal();
                                }}
                                variant="contained"
                                component={RouterLink}
                                to="#"
                                startIcon={<Iconify icon="tabler:edit" />}
                                style={{ marginRight: 10 }}
                            >
                                Bulk Edit
                            </Button>
                            <Button
                                onClick={() => {
                                    setOpenDrawer(true);
                                }}
                                variant="contained"
                                component={RouterLink}
                                to="#"
                                startIcon={<Iconify icon="bi:filter" />}
                                style={{ marginRight: 10 }}
                            >
                                Filter
                            </Button>
                        </>
                    }
                </Stack>

                <Card>
                    <Box
                        p={2}
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            flex: 1
                        }}
                        className="top_column"
                    >
                        <Searchbar
                            width={'100%'}
                            value={search}
                            setFunction={(val) => {
                                setsearch(val);
                            }}
                            className="width_full_property"
                            placeholder={t('Agents.search')}
                            onEnter={() => {
                                setsearchLoading(true);
                                list.length = 0;
                                getUsers();
                            }}
                        />

                        <LoadingButton
                            style={{ width: '15%', marginLeft: '2.5%' }}
                            variant="contained"
                            loading={searchLoading}
                            onClick={() => {
                                setsearchLoading(true);
                                setPage(1);
                                getUsers(1);
                            }}
                            className="width_full_status"
                        >
                            <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
                        </LoadingButton>
                    </Box>

                    {userData.role.id == 4 || userData.role.id == 5 || userData.role.id == 7 && <Box
                        p={2}
                        style={{
                            flex: 1
                        }}
                        className="top_column"
                    >
                        <Box sx={{ display: "flex" }}>
                            <Typography sx={{ width: 170 }} variant="subtitle2" gutterBottom>
                                Qualification Title :
                            </Typography>
                            <Typography sx={{ flex: 1, textAlign: "start" }} variant="subtitle2" gutterBottom>
                                {qualificationTitle}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                            <Typography sx={{ width: 170 }} variant="subtitle2" gutterBottom>
                                Qualification Number :
                            </Typography>
                            <Typography sx={{ flex: 1, textAlign: "start" }} variant="subtitle2" gutterBottom>
                                {qualificationNumber}
                            </Typography>
                        </Box>
                    </Box>
                    }

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    headLabel={userData.role.id == 4 && ASSESSORS_TABLE_HEAD ||
                                        userData.role.id == 3 && LEARNER_TABLE_HEAD ||
                                        userData.role.id == 5 && IQA_TABLE_HEAD ||
                                        userData.role.id == 7 && EQA_TABLE_HEAD ||
                                        LEARNER_TABLE_HEAD
                                    }
                                    numSelected={selected.length}
                                    onSelectAllClick={handleSelectAllClick}
                                    allSelect={selected.length == list.length && list.length > 0}
                                />
                                <TableBody>
                                    {list.map((row, index) => {
                                        return <Table_Row row={row} key={index} />;
                                    })}
                                </TableBody>

                                {isUserNotFound && !loading && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={9}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                                                    <SearchNotFound message={`No Learner Found`} />
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                            {loading && !searchLoading && (
                                <Box
                                    py={5}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flex: 1
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </TableContainer>
                    </Scrollbar>
                </Card>
                {!loading && !searchLoading && pagesCount > 1 && (
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            marginTop: 10,
                            alignItems: 'center'
                        }}
                    >
                        <Pagination
                            count={pagesCount}
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageChange}
                            page={page}
                        />
                    </Box>
                )}
            </Container>

            <Modal
                open={openModal}
                onClose={handleModalClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style3}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        IQA Activity
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        {selectedMessage}
                    </Typography>
                    <Button onClick={handleModalClose} variant="contained" color="primary" sx={{ mt: 2 }}>
                        Close
                    </Button>
                </Box>
            </Modal>

            <Modal
                open={openModalList}
                onClose={handleModalCloseList}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={[style3, { maxWidth: "70%", height: "80%" }]}>
                    <Card sx={{ flex: 1 }}>
                        {/* <Box
                            p={2}
                            style={{
                                flexDirection: 'row',
                                display: 'flex',
                                flex: 1
                            }}
                            className="top_column"
                        >
                            <Searchbar
                                width={'100%'}
                                value={search}
                                setFunction={(val) => {
                                    setsearch(val);
                                }}
                                className="width_full_property"
                                placeholder={t('Agents.search')}
                                onEnter={() => {
                                    setsearchLoading(true);
                                    list.length = 0;
                                    getUsers();
                                }}
                            />

                            <LoadingButton
                                style={{ width: '15%', marginLeft: '2.5%' }}
                                variant="contained"
                                loading={searchLoading}
                                onClick={() => {
                                    setsearchLoading(true);
                                    setPage(1);
                                    getUsers(1);
                                }}
                                className="width_full_status"
                            >
                                <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
                            </LoadingButton>
                        </Box> */}
                        {/* 
                        <Box
                            p={2}
                            style={{
                                flex: 1
                            }}
                            className="top_column"
                        >
                            <Box sx={{ display: "flex" }}>
                                <Typography sx={{ width: 170 }} variant="subtitle2" gutterBottom>
                                    Qualification Title :
                                </Typography>
                                <Typography sx={{ flex: 1, textAlign: "start" }} variant="subtitle2" gutterBottom>
                                    {qualificationTitle}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex" }}>
                                <Typography sx={{ width: 170 }} variant="subtitle2" gutterBottom>
                                    Qualification Number :
                                </Typography>
                                <Typography sx={{ flex: 1, textAlign: "start" }} variant="subtitle2" gutterBottom>
                                    {qualificationNumber}
                                </Typography>
                            </Box>
                        </Box> */}

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800, maxHeight: "100%" }}>
                                <Table>
                                    <UserListHead
                                        headLabel={ASSESSORS_LIST_TABLE_HEAD}
                                    />
                                    <TableBody>
                                        {listAssessors.map((row, index) => {
                                            const {
                                                id,
                                                full_name,
                                                seen,
                                                qualifications,
                                                ref_number,
                                                assessors,
                                                iqas,
                                                completion,
                                                cohort_batch_no,
                                                user_qualifications
                                            } = row;
                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    sx={{
                                                        backgroundColor: user_qualifications[0]?.disability == 1 ? 'rgba(255 ,54, 1, 0.3)' : 'transparent',
                                                    }}
                                                >
                                                    {/* NAME */}
                                                    <TableCell padding="normal" style={{ width: 150 }}>
                                                        <Typography style={{ width: 150 }} variant="subtitle2" flexWrap={'wrap'}>
                                                            {full_name === 'undefined' ? 'N/A' : full_name}
                                                        </Typography>
                                                    </TableCell>

                                                    {/* PROGRESS */}
                                                    <TableCell align="left" style={{ width: 80 }}>
                                                        <Typography flexWrap={'wrap'} style={{ width: 80 }}>
                                                            {user_qualifications[0]?.progress || 'N/A'}
                                                        </Typography>
                                                    </TableCell>

                                                    {/* Qualification/Subject */}
                                                    <TableCell align="left" style={{ width: 120 }}>
                                                        <Typography flexWrap={'wrap'} style={{ width: 120 }}>
                                                            {user_qualifications[0]?.qualifications_with_trash[0]?.sub_title || 'N/A'}
                                                        </Typography>
                                                    </TableCell>

                                                    {/* Sampling Requirement */}
                                                    <TableCell align="left" style={{ width: 80 }}>
                                                        <Typography flexWrap={'wrap'} style={{ width: 80 }}>
                                                            {user_qualifications[0]?.assessors_with_trash[0]?.user_qualifications[0]?.sampling_ratio ?
                                                                `${user_qualifications[0]?.assessors_with_trash[0]?.user_qualifications[0]?.sampling_ratio}%` :
                                                                '0%'}
                                                        </Typography>
                                                    </TableCell>

                                                    {/* Sampling Conducted*/}
                                                    <TableCell align="left" style={{ width: 80 }}>
                                                        <Typography flexWrap={'wrap'} style={{ width: 80 }}>
                                                            {`${user_qualifications[0]?.sampling_age}` || 'N/A'}
                                                        </Typography>
                                                    </TableCell>

                                                </TableRow>
                                            )
                                        }
                                        )}
                                    </TableBody>

                                    {isAssessorNotFound && !loadingAssessor && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={9}>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                                                        <SearchNotFound message={`No Learner Found`} />
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                                {loadingAssessor && !searchLoading && (
                                    <Box
                                        py={5}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flex: 1
                                        }}
                                    >
                                        <CircularProgress />
                                    </Box>
                                )}
                            </TableContainer>
                        </Scrollbar>
                    </Card>
                    {!loadingAssessor && !searchLoading && pagesCount2 > 1 && (
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                marginTop: 10,
                                alignItems: 'center'
                            }}
                        >
                            <Pagination
                                count={pagesCount2}
                                color="primary"
                                variant="outlined"
                                shape="rounded"
                                onChange={handlePageChange2}
                                page={page2}
                            />
                        </Box>
                    )}
                    <Button onClick={handleModalCloseList} variant="contained" color="primary" sx={{ mt: 2 }}>
                        Close
                    </Button>
                </Box>
            </Modal>
        </Page>
    );
}
