import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Outlet, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import Page from '../components/Page';
import { Alert, Button, Card, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Pagination, Paper, Select, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TableSortLabel, TextField, Typography, useTheme } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { LoadingButton } from '@mui/lab';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import { api } from 'src/Api';
import { UserListHead } from 'src/sections/@dashboard/user';
import moment from 'moment';
import pallete from '../theme/palette';
import { PausePresentationTwoTone } from '@mui/icons-material';

import DeleteIcon from '@mui/icons-material/Delete';
import Iframe from 'src/components/Users/Iframe';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function IQAReports({ id, qualification_id }) {
    console.log("🚀 ~ IQAReports ~ qualification_id:", qualification_id)

    const theme = useTheme();
    const userData = JSON.parse(localStorage.getItem('user'));

    const TABLE_HEAD = [
        { id: 'title', label: "Title", alignRight: false },
        { id: 'name', label: "Name", alignRight: false },
        { id: 'IQA_sampling_date', label: "IQA Sampling Date", alignRight: false },
        { id: 'qualification name', label: "Qualification Name", alignRight: false },
        { id: 'view_report', label: "View Report", alignRight: false },
    ];

    const TABLE_HEAD_COMPANY = [
        { id: 'iqa_name', label: "IQA Name", alignRight: false },
        { id: 'title', label: "Title", alignRight: false },
        { id: 'name', label: "Name", alignRight: false },
        { id: 'IQA_sampling_date', label: "IQA Sampling Date", alignRight: false },
        { id: 'qualification name', label: "Qualification Name", alignRight: false },
        { id: 'view_report', label: "View Report", alignRight: false },
    ];

    const [permission, setpermission] = useState([]);
    const requirement = ['visible', 'add', 'edit', 'delete'];

    const [IQAData, setIQAData] = useState([]);
    const [search, setsearch] = useState('');
    const [loading, setloading] = useState(true);
    const [searchLoading, setsearchLoading] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [page, setPage] = useState(1);
    const [addModal, setAddModal] = useState(false);

    const [selectedAssessors, setSelectedAssessors] = useState([]);
    const [selectedLearners, setSelectedLearners] = useState([]);
    const [selectedQualifications, setSelectedQualifications] = useState([]);

    const [qualificationsFilter, setQualificationFilter] = useState([]);
    const [selectedQualificationsFilter, setSelectedQualificationsFilter] = useState([]);
    const [typesFilter, setTypesFilter] = useState("");

    const [assessors, setAssessors] = useState([]);
    const [qualifications, setQualification] = useState([]);

    const [errorUserDropDown, setErrorUserDropDown] = useState(false);
    const [loadingUserDropDown, setLoadingUserDropDown] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);

    const [dateSampling, setDateSampling] = useState(new Date().toISOString().split('T')[0]);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [title, setTitle] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [learners, setlearners] = useState([]);
    const [types, setTypes] = useState("");

    const [iframeURL, setIframeURL] = useState('');
    const [iframeModal, setIframeModal] = useState(false);

    const isIQANotFound = IQAData.length === 0;

    useEffect(() => {
        getIQAReports(page);
    }, [page, selectedQualificationsFilter, typesFilter]);

    useEffect(() => {
        checkRender();
    }, []);

    useEffect(() => {
        if (search.length == 0) {
            IQAData.length = 0;
            getIQAReports(page);
        }
    }, [search, selectedQualificationsFilter, typesFilter]);

    const handlePageChange = (event, value) => {
        IQAData.length = 0;
        setPage(value);
        getQualifications(value);
    };

    const refreshAdmin = () => {
        setAddModal(false);
        IQAData.length = 0;
        getIQAReports(page);
    };

    function checkRender() {
        let check = 'iq reports';
        const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
            (item) => item.permission_name.toLowerCase() == check
        );
        localPerm[0]?.permission_types?.filter((items) =>
            requirement.includes(items.permission_type_name.toLowerCase())
                ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
                : false
        );
    }

    const getAssessors = () => {

        setLoadingUserDropDown(true)

        const token = localStorage.getItem('token');
        const userData = JSON.parse(localStorage.getItem('user'));

        const qualificationCheck = qualifications
            .filter((a) => selectedQualifications.includes(a.sub_title))
            .map((a) => a.id);

        const queryParams = new URLSearchParams();
        queryParams.append('company_admin_id', userData?.company_admin_id);
        qualificationCheck?.length > 0 && queryParams.append('qualification_id', qualificationCheck.join(','));
        userData.role.id == 5 && queryParams.append('iqa_id', userData.id);

        fetch(`${api.get_assessors}?${queryParams.toString()}`, {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token}`
            })
        })
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setAssessors(result.data);
                    setLoadingUserDropDown(false)
                } else {
                    setAssessors([]);
                    setLoadingUserDropDown(false)
                    setErrorUserDropDown(true)
                }
            })
            .catch((error) => {
                console.log('error', error)
                setLoadingUserDropDown(false)
                setErrorUserDropDown(true)
            });
    };

    const getQualifications = () => {
        setLoadingUserDropDown(true)

        const token = localStorage.getItem('token');
        const userData = JSON.parse(localStorage.getItem('user'));
        fetch(`${api.get_qualifications}?company_admin_id=${userData?.company_admin_id}`, {
            method: 'GET',
            headers: new Headers({
                Authorization: `Bearer ${token}`
            })
        })
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setQualification(result.data);
                    setQualificationFilter(result.data);
                    setLoadingUserDropDown(false)
                } else {
                    setLoadingUserDropDown(false)
                    setErrorUserDropDown(true)
                    setQualification([]);
                    setQualificationFilter([]);
                }
            })
            .catch((error) => {
                console.log('error', error)
                setErrorUserDropDown(true)
                setLoadingUserDropDown(false)
            });
    };

    function getIQAReports(page) {
        console.log('qualification_id', qualification_id)
        setloading(true);
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));

        myHeaders.append('Authorization', `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const qualificationCheck = qualificationsFilter
            .filter((a) => selectedQualificationsFilter.includes(a.sub_title))
            .map((a) => a.id);

        const queryParams = new URLSearchParams();
        search.length > 0 && queryParams.append('search', search);
        page && queryParams.append('page', page);
        queryParams.append('company_admin_id', user?.company_admin_id);
        qualification_id && queryParams.append('qualification_id', qualification_id);
        qualificationCheck?.length > 0 && queryParams.append('parent_qualification_id', qualificationCheck.join(','));
        typesFilter && queryParams.append('type', typesFilter);
        id && queryParams.append('user_id', id);

        // Append the user_id as a query parameter to the API endpoint URL
        const url = `${api.get_iqa_report}?${queryParams.toString()}`;
        fetch(url, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setIQAData(result.data);
                    result?.count ? setpagesCount(Math.ceil(result?.count / 10)) : setpagesCount(1);
                } else {
                    setIQAData([]);
                    setpagesCount(0);
                }
            })
            .catch((error) => console.log('error', error))
            .finally(() => {
                setloading(false);
                setsearchLoading(false);
            });
    }

    function getLearners() {
        const myHeaders = new Headers();

        setLoadingUserDropDown(true)

        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        myHeaders.append('Authorization', `Bearer ${token}`);

        const qualificationCheck = qualifications
            .filter((a) => selectedQualifications.includes(a.sub_title))
            .map((a) => a.id);

        const queryParams = new URLSearchParams();
        queryParams.append('company_admin_id', userData?.company_admin_id);
        qualificationCheck?.length > 0 && queryParams.append('qualification_id', qualificationCheck.join(','));
        userData.role.id == 5 && queryParams.append('iqa_id', userData.id);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        // Append the user_id as a query parameter to the API endpoint URL
        const url = `${api.get_learners}?${queryParams.toString()}`;

        fetch(url, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 200) {
                    setlearners(result.data);
                    setLoadingUserDropDown(false)
                } else {
                    setlearners([]);
                    setLoadingUserDropDown(false)
                }
            })
            .catch((error) => console.log('error', error))
            .finally(() => { });
    }

    const handleCloseAddModal = () => {
        setAddModal(false);
        setDateSampling(new Date().toISOString().split('T')[0])
        setSelectedFile(null);
        setSelectedAssessors([]);
        setSelectedQualifications([])
        setTypes("")
        setSelectedLearners([])
        setTitle('')
    }

    const handleOpenAddModal = () => {
        setAddModal(true)
    }

    const handleChangeAssessors = (event) => {
        const value = event.target.value;
        setSelectedAssessors(typeof value === 'string' ? value.split(',') : value);
    };

    const handleChangeLearners = (event) => {
        const value = event.target.value;
        setSelectedLearners(typeof value === 'string' ? value.split(',') : value);
    };

    const handleChangeQualifications = (event) => {
        const value = event.target.value;
        setSelectedQualifications(typeof value === 'string' ? value.split(',') : value);
    };

    const handleChangeQualificationsFilter = (event) => {
        const value = event.target.value;
        setSelectedQualificationsFilter(typeof value === 'string' ? value.split(',') : value);
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleFileDelete = () => {
        setSelectedFile(null);
    };

    const handleSubmit = () => {

        if (!dateSampling || !title || !types || selectedQualifications.length == 0 || !selectedFile) {
            setSnackbarMessage("Please enter all field");
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return
        }

        setSubmitLoading(true)
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);

        const qualificationCheck = qualifications
            .filter((a) => selectedQualifications.includes(a.sub_title))
            .map((a) => a.id);
        const AssessorCheck = assessors
            .filter((a) => selectedAssessors.includes(a.full_name))
            .map((a) => a.id);
        const LearnerCheck = learners
            .filter((a) => selectedLearners.includes(a.full_name))
            .map((a) => a.id);

        const formdata = new FormData();
        if (dateSampling) formdata.append('date', dateSampling); // Added dateSampling value
        if (qualificationCheck?.length > 0) formdata.append('qualification_id', qualificationCheck?.join(','));
        if (AssessorCheck.length > 0) formdata.append('assessor_id', AssessorCheck.join(','));
        if (LearnerCheck.length > 0) formdata.append('learner_id', LearnerCheck.join(','));
        if (selectedFile) formdata.append('file', selectedFile);
        if (title) formdata.append('title', title);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow',
        };

        fetch(api.save_iqa_report, requestOptions)
            .then((response) =>
                response.text().then((result) => ({ status: response.status, body: result }))
            )
            .then(({ status, body }) => {
                const result = JSON.parse(body);
                if (status === 201) {
                    setSubmitLoading(false)
                    setSnackbarMessage(result.message);
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                    refreshAdmin();
                    handleCloseAddModal()
                } else {
                    setSubmitLoading(false)
                    setSnackbarMessage(result.error);
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                }
            })
            .catch((error) => {
                setSubmitLoading(false)
                setSnackbarMessage('An error occurred.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                handleCloseAddModal()
            });

    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const typeData = [
        { name: "Assessors" },
        { name: "Learners" }
    ]

    const handleCloseIframe = () => {
        setIframeURL('')
        setIframeModal(false)
    }

    const handleCancelFilter = () => {
        setTypesFilter('')
        setSelectedQualificationsFilter([])
    }

    return (
        <Page title={`iq_reports | ${process.env.REACT_APP_PROJECTNAME}`}>
            <Container style={{ padding: qualification_id && "0px 0px 0px 0px" }}>
                {!qualification_id && <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    {!qualification_id && <Typography variant="h4" gutterBottom>
                        IQ Reports
                    </Typography>}
                    {permission.includes('add') &&
                        <>
                            {!qualification_id && <Button
                                variant="contained"
                                startIcon={<Iconify icon="eva:plus-fill" />}
                                onClick={handleOpenAddModal}
                            >
                                Add
                            </Button>}
                        </>
                    }
                </Stack>}

                <Card>
                    {!qualification_id && <>
                        <Box
                            p={2}
                            style={{
                                flexDirection: 'row',
                                display: 'flex',
                                flex: 1
                            }}
                            className="top_column"
                        >
                            <Searchbar
                                width={'90%'}
                                value={search}
                                setFunction={(val) => {
                                    setsearch(val);
                                }}
                                className="width_full_property"
                                placeholder={'Search Reports'}
                                onEnter={() => {
                                    setsearchLoading(true);
                                    IQAData.length = 0;
                                    getIQAReports(1);
                                }}
                            />
                            <LoadingButton
                                style={{ width: '15%', marginLeft: '2.5%' }}
                                variant="contained"
                                loading={searchLoading}
                                onClick={() => {
                                    setsearchLoading(true);
                                    setPage(1);
                                    getIQAReports(1);
                                }}
                                className="width_full_status"
                            >
                                <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
                            </LoadingButton>

                        </Box>
                        <Box
                            px={2}
                            style={{
                                display: 'flex',
                                flex: 1,
                                justifyContent: "space-between",
                            }}
                            className="top_column"
                        >
                            <FormControl sx={{ mb: 0 }} fullWidth variant="outlined">
                                <InputLabel id="qualification-label">Select Qualification/s</InputLabel>
                                <Select
                                    onOpen={getQualifications}
                                    labelId="qualification-label"
                                    id="qualification"
                                    value={selectedQualificationsFilter}
                                    onChange={handleChangeQualificationsFilter}
                                    input={<OutlinedInput id="select-multiple-chip" label="Select Qualification/s" />}
                                    // MenuProps={MenuProps}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Typography key={value} label={value}>
                                                    {value}
                                                </Typography>
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {loadingUserDropDown ? (
                                        <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                                            <CircularProgress size={24} color="inherit" />
                                        </MenuItem>
                                    ) : errorUserDropDown ? (
                                        <MenuItem disabled>No data found</MenuItem>
                                    ) : (
                                        qualificationsFilter.map((name) => (
                                            <MenuItem
                                                key={name.id}
                                                value={name.sub_title}
                                                style={getStyles(name, selectedQualificationsFilter, theme)}
                                            >
                                                {name.sub_title}
                                            </MenuItem>
                                        ))
                                    )}
                                </Select>
                            </FormControl>

                            <TextField
                                select
                                fullWidth
                                label="Select Type"
                                variant="outlined"
                                required
                                name="type"
                                value={typesFilter}
                                onChange={(e) => { setTypesFilter(e.target.value) }}
                                sx={{ mb: 0, marginLeft: 1 }}
                            >
                                {typeData.map((item) => (
                                    <MenuItem key={item.name} value={item.name}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <LoadingButton
                                style={{ width: '29.5%', marginLeft: '2.5%' }}
                                variant="contained"
                                onClick={() => {
                                    handleCancelFilter()
                                }}
                                className="width_full_status"
                            >
                                {/* <Iconify icon="ic:outline-cancel" sx={{ width: 25, height: 25 }} /> */}
                                Cancel
                            </LoadingButton>
                        </Box>
                    </>
                    }

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 600 }}>
                            <Table>
                                <UserListHead headLabel={userData?.role?.id == 2 ? TABLE_HEAD_COMPANY : TABLE_HEAD} rowCount={IQAData.length} />

                                <TableBody>
                                    {IQAData.map((row) => {
                                        const { id, date, attachement, title, qualification, iqa, assessor, learner } = row;
                                        return (
                                            <TableRow key={id}>
                                                {userData.role.id == 2 && <TableCell noWrap align="left">
                                                    {iqa?.iqa_name || 'N/A'}
                                                </TableCell>}
                                                <TableCell noWrap align="left">
                                                    {title || 'N/A'}
                                                </TableCell>
                                                <TableCell noWrap align="left">
                                                    {
                                                        assessor && assessor.assessor_name ||
                                                        learner && learner.learner_name ||
                                                        'N/A'
                                                    }
                                                </TableCell>
                                                <TableCell noWrap align="left">
                                                    {date ? moment(date).format('DD/MM/YYYY') : 'N/A'}
                                                </TableCell>
                                                <TableCell noWrap align="left">
                                                    {qualification.qualification_title || 'N/A'}
                                                </TableCell>

                                                <TableCell noWrap align="left" >
                                                    {attachement ? (
                                                        <Button
                                                            // target='blank'
                                                            size="small"
                                                            style={{
                                                                justifyContent: 'flex-start',
                                                                alignSelf: 'center',
                                                                marginRight: 5
                                                            }}
                                                            variant="outlined"
                                                            component={RouterLink}
                                                            // to={attachement}
                                                            onClick={() => {
                                                                setIframeModal(true)
                                                                setIframeURL(attachement)
                                                            }}
                                                            startIcon={
                                                                <Iconify color={pallete.primary.main} icon="carbon:view-filled" />
                                                            }
                                                        >
                                                            Click to see
                                                        </Button>
                                                    ) : (
                                                        'N/A'
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {isIQANotFound && !loading && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound message={'No IQ Report Found'} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>

                            {loading && !searchLoading && (
                                <Box
                                    py={5}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flex: 1
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </TableContainer>
                    </Scrollbar>
                </Card>

                {!loading && !searchLoading && pagesCount > 1 && (
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            marginTop: 10,
                            alignItems: 'center'
                        }}
                    >
                        <Pagination
                            count={pagesCount}
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageChange}
                            page={page}
                        />
                    </Box>
                )}
            </Container>

            <Dialog
                open={addModal}
                onClose={handleCloseAddModal}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                maxWidth="md"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="dialog-title">Add IQA Reports</DialogTitle>
                <DialogContent dividers>
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    type="date"
                                    label="Date of Sampling"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    name="DateofSampling"
                                    value={dateSampling}
                                    onChange={(e) => setDateSampling(e.target.value)}
                                    sx={{ mb: 0 }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    type="title"
                                    label="Title"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    name="title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    sx={{ mb: 0 }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Select Type"
                                    variant="outlined"
                                    required
                                    name="type"
                                    value={types}
                                    onChange={(e) => { setTypes(e.target.value) }}
                                    sx={{ mb: 0 }}
                                >
                                    {typeData.map((item) => (
                                        <MenuItem key={item.name} value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl sx={{ mb: 0 }} fullWidth variant="outlined">
                                    <InputLabel id="qualification-label">Select Qualification/s</InputLabel>
                                    <Select
                                        onOpen={getQualifications}
                                        labelId="qualification-label"
                                        id="qualification"
                                        value={selectedQualifications}
                                        onChange={handleChangeQualifications}
                                        input={<OutlinedInput id="select-multiple-chip" label="Select Qualification/s" />}
                                        MenuProps={MenuProps}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        {loadingUserDropDown ? (
                                            <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                                                <CircularProgress size={24} color="inherit" />
                                            </MenuItem>
                                        ) : errorUserDropDown ? (
                                            <MenuItem disabled>No data found</MenuItem>
                                        ) : (
                                            qualifications.map((name) => (
                                                <MenuItem
                                                    key={name.id}
                                                    value={name.sub_title}
                                                    style={getStyles(name, selectedQualifications, theme)}
                                                >
                                                    {name.sub_title}
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {types === "Assessors" && selectedQualifications.length !== 0 && (
                                <Grid item xs={12} sm={6}>
                                    <FormControl sx={{ mb: 0 }} fullWidth variant="outlined">
                                        <InputLabel id="Assessor-label">Select Assessor</InputLabel>
                                        <Select
                                            onOpen={getAssessors}
                                            labelId="Assessor-label"
                                            id="Assessor"
                                            value={selectedAssessors}
                                            onChange={handleChangeAssessors}
                                            input={<OutlinedInput id="select-multiple-chip" label="Select Assessor" />}
                                            MenuProps={MenuProps}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={value} />
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            {loadingUserDropDown ? (
                                                <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                                                    <CircularProgress size={24} sx={{ color: "#000000" }} />
                                                </MenuItem>
                                            ) : errorUserDropDown ? (
                                                <MenuItem disabled>No data found</MenuItem>
                                            ) : (
                                                assessors.map((name) => (
                                                    <MenuItem
                                                        key={name.id}
                                                        value={name.full_name}
                                                        style={getStyles(name, selectedAssessors, theme)}
                                                    >
                                                        {name.full_name}
                                                    </MenuItem>
                                                ))
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}

                            {types === "Learners" && selectedQualifications.length !== 0 && (
                                <Grid item xs={12} sm={6}>
                                    <FormControl sx={{ mb: 0 }} fullWidth variant="outlined">
                                        <InputLabel id="learners-label">Select Learners</InputLabel>
                                        <Select
                                            onOpen={getLearners}
                                            labelId="learners-label"
                                            id="learners"
                                            value={selectedLearners}
                                            onChange={handleChangeLearners}
                                            input={<OutlinedInput id="select-multiple-chip" label="Select Learner" />}
                                            MenuProps={MenuProps}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={value} />
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            {loadingUserDropDown ? (
                                                <MenuItem sx={{ display: "flex", justifyContent: "center" }} disabled>
                                                    <CircularProgress size={24} sx={{ color: "#000000" }} />
                                                </MenuItem>
                                            ) : errorUserDropDown ? (
                                                <MenuItem disabled>No data found</MenuItem>
                                            ) : (
                                                learners.map((name) => (
                                                    <MenuItem
                                                        key={name.id}
                                                        value={name.full_name}
                                                        style={getStyles(name, selectedLearners, theme)}
                                                    >
                                                        {name.full_name}
                                                    </MenuItem>
                                                ))
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <input
                                    accept="*"
                                    id="upload-file"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="upload-file">
                                    <LoadingButton variant="outlined" component="span">
                                        Upload File
                                    </LoadingButton>
                                </label>
                                {selectedFile && (
                                    <Box display="flex" alignItems="center" mt={2}>
                                        <span>{selectedFile.name}</span>
                                        <IconButton onClick={handleFileDelete} color="error">
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button disabled={submitLoading} variant="contained" color="primary" onClick={handleCloseAddModal}>
                        Cancel
                    </Button>
                    <LoadingButton loading={submitLoading} variant="contained" color="primary" sx={{ ml: 2 }} onClick={handleSubmit}>
                        Submit
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Iframe url={iframeURL} setDialogOpen={handleCloseIframe} dialogOpen={iframeModal} />

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </Page >
    )
}

export default IQAReports
