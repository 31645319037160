import React, { useState, useEffect } from 'react';
import { Box, TextField, Grid, Chip, Alert, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { api } from 'src/Api';
import MultiSelectDropDown from 'src/components/MultiSelectDropDown';
import MultiSelectReference from 'src/components/MultiSelectReference';
import MultiSelectLearners from 'src/components/MultiSelectLearners';

const EQAsForm = ({ formData, setFormData, handleSubmit, editable, error, setAlert, loading }) => {
  const handleChange = (e) => {
    setAlert({ visible: false, message: '' });
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSelectChange = (event) => {
    const {
      target: { value }
    } = event;
    setFormData((prevData) => ({
      ...prevData,
      qualification: typeof value === 'string' ? value.split(',') : value
    }));
  };

  const handleSelectChangeReference = (event) => {
    const {
      target: { value }
    } = event;
    setFormData((prevData) => ({
      ...prevData,
      reference: typeof value === 'string' ? value.split(',') : value
    }));
  };
  const handleSelectChangeLearners = (event) => {
    const {
      target: { value }
    } = event;
    setFormData((prevData) => ({
      ...prevData,
      learner: typeof value === 'string' ? value.split(',') : value
    }));
  };

  const [qualifications, setqualifications] = useState([]);

  function getQualifications() {
    const myHeaders = new Headers();

    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    // Append the user_id as a query parameter to the API endpoint URL
    const url = `${api.get_qualificationsV1}?user_id=${user?.id}&company_admin_id=${user?.company_admin_id}&isEdit=${false}`;

    fetch(url, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setqualifications(result.data);
        } else {
          setqualifications([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => { });
  }

  const [references, setrefrences] = useState([]);

  function getRefrences() {
    const myHeaders = new Headers();

    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    // Append the user_id as a query parameter to the API endpoint URL
    const url = `${api.get_references}?user_id=${user?.id}&company_admin_id=${user?.company_admin_id}&qualifications=${formData.qualification}`;

    fetch(url, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setrefrences(result.data);
        } else {
          setrefrences([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => { });
  }

  const [learners, setlearners] = useState([]);

  function getLearners() {
    const myHeaders = new Headers();

    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const qualificationIds = formData.qualification.map(q => q.id);
    
    // Append the user_id as a query parameter to the API endpoint URL
    const url = `${api.get_learners}?company_admin_id=${user?.company_admin_id}&qualifications=${JSON.stringify(qualificationIds)}`;

    console.log("🚀 ~ getLearners ~ formData.qualification:", formData.qualification)
    fetch(url, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setlearners(result.data);
        } else {
          setlearners([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => { });
  }

  useEffect(() => {
    getQualifications();
    // getRefrences();
    // getLearners();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        p: 0,
        paddingTop: 2,
        paddingBottom: 0
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            label="Surname*"
            variant="outlined"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </Grid>
        {/* QUALIFICATION DROPDOWN */}
        <Grid item xs={12} sm={6}>
          <MultiSelectDropDown
            data={qualifications}
            disabled={!editable}
            required
            id="qualification"
            lableId="qualification-label"
            label={'Select Qualification/s'}
            value={formData.qualification}
            onChange={handleSelectChange}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value?.id} label={value?.sub_title} />
                ))}
              </Box>
            )}
          />
        </Grid>
        {/* REFERENCES DROPDOWN */}
        {/* <Grid item xs={12} sm={6}>
          <MultiSelectReference
            onOpen={getRefrences}
            data={references}
            disabled={!editable}
            id="reference"
            lableId="qualification-label"
            label={'Select Reference/s'}
            value={formData.reference}
            onChange={handleSelectChangeReference}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value, index) => (
                  <Chip key={index} label={value} />
                ))}
              </Box>
            )}
          />
        </Grid> */}
        {/* LEARNERS DROPDOWN */}
        <Grid item xs={12} sm={6}>
          <MultiSelectLearners
            onOpen={getLearners}
            data={learners}
            disabled={!editable}
            id="learner"
            lableId="qualification-label"
            label={'Select Learner/s'}
            value={formData.learner}
            onChange={handleSelectChangeLearners}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value, index) => (
                  <Chip key={index} label={value?.full_name} />
                ))}
              </Box>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            type="email"
            label="Email ID"
            variant="outlined"
            name="email"
            required
            value={formData.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editable}
            fullWidth
            type="date"
            label="Account Expiry Date"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            name="accountExpiryDate"
            value={formData.accountExpiryDate}
            onChange={handleChange}
          />
        </Grid>

        {error?.visible && (
          <Grid item xs={12}>
            <Alert style={{ marginBottom: 20 }} severity="error">
              {error?.message}
            </Alert>
          </Grid>
        )}
        {editable && (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                handleSubmit(formData);
              }}
              loading={loading}
            >
              Submit
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default EQAsForm;
