import React, { useState, useEffect } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Avatar,
  Grid,
  Container,
  IconButton,
  CircularProgress,
  Card,
  CardContent,
  Stack
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import Chart from 'react-apexcharts';
import { useNavigate, useParams } from 'react-router-dom';
import Page from 'src/components/Page';
import OptionsTab from 'src/components/Users/learnerDetails/OptionsTab';
import { api } from 'src/Api';
import SubmissionTab from 'src/components/Users/learnerDetails/SubmissionTab';
import DocsTab from 'src/components/Users/learnerDetails/DocsTab';
import ResourceMaterialTab from 'src/components/Users/learnerDetails/ResourceMaterialTab';
import palette from 'src/theme/palette';
import moment from 'moment';
import AssessorDoc from 'src/components/Users/learnerDetails/AssessorDoc';
import IQAReports from 'src/pages/IQAReports';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const LearnerDetails = () => {
  const navigate = useNavigate();
  const { id, qualificationId } = useParams();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [circleChart, setCircleChart] = useState([70, 30]);
  console.log("🚀 ~ LearnerDetails ~ circleChart:", circleChart)
  const [barCategories, setBarCategories] = useState([70, 30]);
  const [barData, setBarData] = useState([70, 30]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const userDataLocal = JSON.parse(localStorage.getItem('user'));
  console.log("🚀 ~ LearnerDetails ~ userDataLocal:", userDataLocal)

  const pieOptions = {
    chart: {
      type: 'pie'
    },
    labels: ['Completed', 'Incomplete'],
    colors: ['#00ff40', '#ff0000'],
    legend: {
      position: 'bottom',
      offsetY: 10
    }
  };

  // const pieSeries = ;

  const barOptions = {
    chart: {
      id: 'learner-progress',
      type: 'bar'
    },
    xaxis: {
      categories: barCategories
    },
    plotOptions: {
      bar: {
        distributed: true,
        colors: {
          ranges: [
            {
              from: 0,
              to: 30,
              color: '#F44336' // Red for values 0 to 30
            },
            {
              from: 31,
              to: 50,
              color: '#FFC107' // Yellow for values 31 to 50
            },
            {
              from: 51,
              to: 100,
              color: '#4CAF50' // Green for values 51 to 100
            }
          ]
        }
      }
    },
    colors: [
      '#F44336', // Color for first bar
      '#E91E63', // Color for second bar
      '#9C27B0', // Color for third bar
      '#673AB7', // Color for fourth bar
      '#3F51B5', // Color for fifth bar
      '#2196F3', // Color for sixth bar
      '#03A9F4', // Color for seventh bar
      '#00BCD4', // Color for eighth bar
      '#009688', // Color for ninth bar
      '#4CAF50', // Color for tenth bar
      '#8BC34A', // Color for eleventh bar
      '#CDDC39' // Color for twelfth bar
    ]
  };

  const barSeries = [
    {
      name: 'Progress',
      data: barData
    }
  ];

  const [user, setUser] = useState(null);

  const getUserById = () => {
    setLoading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    myHeaders.append('Authorization', `Bearer ${token}`);

    const queryParams = new URLSearchParams();
    id && queryParams.append('user_id', id);
    queryParams.append('company_admin_id', user?.company_admin_id);
    queryParams.append('qualification_id', qualificationId);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.get_user_by_id}?${queryParams.toString()}`, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setUser(result?.data);
        } else {
          setUser(null);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setLoading(false);
      });
  };

  const getSummaryQualification = () => {
    setLoading(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('user'));

    myHeaders.append('Authorization', `Bearer ${token}`);

    const queryParams = new URLSearchParams();
    id && queryParams.append('user_id', id);
    queryParams.append('company_admin_id', userData?.company_admin_id);
    queryParams.append('qualification_id', qualificationId);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.get_summary_user_qualification}?${queryParams.toString()}`, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setCircleChart([result?.complete_percentage, result?.incomplete_percentage]);
        } else {
          setCircleChart(null);
        }
      })
      .catch((error) => console.log('error', error))
      .finally((s) => {
        setLoading(false);
      });
  };

  const getQualificationBar = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('user'));

    myHeaders.append('Authorization', `Bearer ${token}`);

    // const queryParams = new URLSearchParams();
    // id && queryParams.append('user_id', id);
    // queryParams.append('company_admin_id', userData?.company_admin_id);
    // queryParams.append('qualification_id', qualificationId);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${api.get_qualification_bars}`, requestOptions)
      .then((response) =>
        response.text().then((result) => ({ status: response.status, body: result }))
      )
      .then(({ status, body }) => {
        const result = JSON.parse(body);
        if (status === 200) {
          setBarCategories(result.dates)
          setBarData(result.data)
        } else {
          setBarCategories(null)
          setBarData(null)
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
      });
  };

  useEffect(() => {
    getUserById();
  }, []);

  useEffect(() => {
    if (qualificationId) {
      getSummaryQualification();
      getQualificationBar()
    }
  }, [qualificationId]);

  const onRefresh = () => {
    getUserById();
  };

  return (
    <Page title={`Learner Details | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container sx={{ padding: "5px 20px 0px 20px" }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color="inherit" size={50} />
          </Box>
        ) : (
          <Box sx={{ flexGrow: 1 }}>
            <Stack direction="row" alignItems="center" mb={4}>
              <IconButton style={{ marginRight: 12, marginBottom: 5 }} onClick={() => navigate(-1)}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h4" gutterBottom>
                {user?.full_name}
              </Typography>
            </Stack>

            <Box sx={{ width: '100%' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                TabIndicatorProps={{ style: { display: 'none' } }} // Hide the default indicator
                sx={{
                  '& .MuiTab-root': {
                    textTransform: 'none',
                    backgroundColor: '#f5f5f5', // Background color of each tab
                    margin: '0 8px', // Margin between tabs
                    borderRadius: '8px 8px 0 0', // Rounded corners for each tab,
                    color: 'grey',
                    '&.Mui-selected': {
                      backgroundColor: palette.primary.main, // Background color of the selected tab
                      color: 'white', // Text color of the selected tab,
                      fontWeight: 'bold'
                    }
                  }
                }}
              >
                <Tab disabled={user?.id ? false : true} label="Summary" />
                <Tab disabled={user?.id ? false : true} label="Submissions" />
                {userDataLocal.role.id == 3 && <Tab disabled={user?.id ? false : true} label="Resource Material" />}
                <Tab disabled={user?.id ? false : true} label="Learners Document" />
                {userDataLocal.role.id !== 3 && <Tab disabled={user?.id ? false : true} label="Assessors Document" />}
                {userDataLocal.role.id !== 3 && <Tab disabled={user?.id ? false : true} label="IQA Report" />}
                {userDataLocal.role.id == 2 && <Tab disabled={user?.id ? false : true} label="Options" />}
              </Tabs>
            </Box>

            <TabPanel value={value} index={0} >
              <Grid
                sx={{ padding: "10px 20px 0px 20px" }}
                alignItems="center"
                display={'flex'}
                justifyContent={'space-between'}
                mb={userDataLocal.role.id !== 2 ? -13 : 5}
                pt={userDataLocal.role.id !== 2 ? 4 : 0}
              >
                <Grid item xs={12} md={3} lg={3}>
                  <Chart
                    options={pieOptions}
                    series={circleChart}
                    type="pie"
                    width="300"
                    height={'300'}
                  />
                </Grid>
                <Grid item xs={12} md={7} lg={8}>
                  <Card sx={{ mb: 5, p: 0 }}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h6" gutterBottom>
                            Learner Name
                          </Typography>
                          <Box display={'flex'} alignItems={'center'}>
                            <Avatar
                              alt="Learner Picture"
                              sx={{ width: 35, height: 35, marginRight: 1, border: "1px solid black" }}
                              src={user?.avatar}
                            />
                            <Box alignItems={'center'} justifyContent={"center"} >
                              <Typography style={{ fontSize: 12, lineHeight: 1, marginTop: 4, fontWeight: "1000" }}>
                                {user?.code || "N/A"}
                              </Typography>
                              <Typography style={{ textTransform: 'capitalize', color: user?.user_qualifications[0]?.disability == 1 ? 'rgba(255 54 1)' : '#212B36' }}>
                                {user?.full_name || "N/A"}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h6" gutterBottom>
                            Email
                          </Typography>
                          <Typography >
                            {user?.email || 'N/A'}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant="h6" gutterBottom>
                            Assessor
                          </Typography>
                          <Box display={'flex'} alignItems={'center'}>
                            <Avatar
                              alt="Assessor Picture"
                              sx={{ width: 35, height: 35, marginRight: 1, border: "1px solid black" }}
                              src={user?.user_qualifications[0]?.assessors_with_trash[0]?.avatar}
                            />
                            <Box alignItems={'center'} justifyContent={"center"} >
                              <Typography style={{ textTransform: 'capitalize', fontSize: 12, lineHeight: 1, marginTop: 4, fontWeight: "1000" }}>
                                {user?.user_qualifications[0]?.assessors_with_trash[0]?.code || "N/A"}
                              </Typography>
                              <Typography style={{ textTransform: 'capitalize' }}>
                                {user?.user_qualifications[0]?.assessors_with_trash[0]?.full_name || "N/A"}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant="h6" gutterBottom>
                            Qualification/Subject
                          </Typography>
                          <Typography style={{ textTransform: 'capitalize' }}>
                            {user?.user_qualifications[0]?.qualifications_with_trash[0]?.sub_title || 'N/A'}
                          </Typography>
                        </Grid>

                        {userDataLocal.role.id !== 3 && <Grid item xs={12} sm={6}>
                          <Typography variant="h6" gutterBottom>
                            IQA
                          </Typography>
                          <Box display={'flex'} alignItems={'center'}>
                            <Avatar
                              alt="IQA Picture"
                              sx={{ width: 35, height: 35, marginRight: 1, border: "1px solid black" }}
                              src={user?.user_qualifications[0]?.iqa_with_trash[0]?.avatar}
                            />
                            <Box alignItems={'center'} justifyContent={"center"} >
                              <Typography style={{ textTransform: 'capitalize', fontSize: 12, lineHeight: 1, marginTop: 4, fontWeight: "1000" }}>
                                {user?.user_qualifications[0]?.iqa_with_trash[0]?.code || "N/A"}
                              </Typography>
                              <Typography style={{ textTransform: 'capitalize' }}>
                                {user?.user_qualifications[0]?.iqa_with_trash[0]?.full_name || "N/A"}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        }

                        {userDataLocal.role.id !== 3 &&
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="h6" gutterBottom>
                                Sampling Ratio
                              </Typography>
                              <Typography>
                                {user?.user_qualifications[0]?.assessors_with_trash[0]?.user_qualifications[0]?.sampling_ratio ?
                                  `${user?.user_qualifications[0]?.assessors_with_trash[0]?.user_qualifications[0]?.sampling_ratio}%`
                                  : '0%'}
                              </Typography>
                            </Grid>
                            {circleChart[0] == 100 && <Grid item xs={12} sm={6}>
                              <Typography variant="h6" gutterBottom>
                                Date of Completion
                              </Typography>
                              <Typography>
                                {user?.user_qualifications[0]?.completion_date
                                  ? moment(user?.user_qualifications[0]?.completion_date).format(
                                    'DD/MM/YYYY'
                                  )
                                  : 'N/A'}
                              </Typography>
                            </Grid>}
                          </>

                        }

                        <Grid item xs={12} sm={6}>
                          <Typography variant="h6" gutterBottom>
                            Date of Registration
                          </Typography>
                          <Typography>
                            {user?.user_qualifications[0]?.date_of_registration
                              ? moment(user?.user_qualifications[0]?.date_of_registration).format(
                                'DD/MM/YYYY'
                              )
                              : 'N/A'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              {/* {userDataLocal.role.id == 2 && <Chart options={barOptions} series={barSeries} type="bar" height="500" />} */}
            </TabPanel>

            <TabPanel value={value} index={1}>
              <SubmissionTab
                id={user?.id}
                qualification_id={qualificationId}
              />
            </TabPanel>

            {userDataLocal.role.id == 3 &&
              <TabPanel value={value} index={2}>
                <ResourceMaterialTab qualification_id={qualificationId} onRefresh={onRefresh} />
              </TabPanel>
            }

            <TabPanel value={value} index={(userDataLocal.role.id == 4 || userDataLocal.role.id == 2 || userDataLocal.role.id == 5 || userDataLocal.role.id == 7) ? 2 : 3}>
              <DocsTab
                id={user?.id}
                qualification_id={qualificationId}
              />
            </TabPanel>

            {userDataLocal.role.id !== 3 && < TabPanel value={value} index={3}>
              {/* <OptionsTab id={user?.id} qualification_id={qualificationId} onRefresh={onRefresh} /> */}
              <AssessorDoc id={user?.id} qualification_id={qualificationId} />
            </TabPanel>}

            {userDataLocal.role.id !== 3 && < TabPanel value={value} index={4}>
              {/* <OptionsTab id={user?.id} qualification_id={qualificationId} onRefresh={onRefresh} /> */}
              <IQAReports id={user?.id} qualification_id={qualificationId} />
            </TabPanel>}

            {userDataLocal.role.id == 2 && < TabPanel value={value} index={userDataLocal.role.id == 2 ? 5 : 6}>
              <OptionsTab id={user?.id} qualification_id={qualificationId} onRefresh={onRefresh} />
            </TabPanel>}

          </Box>
        )}
      </Container>
    </Page >
  );
};

export default LearnerDetails;
